import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { auth, firestore } from "./config/firebaseConfig";

import FloatingSupportButton from "./components/FloatingSupportButton";
import TrackOrderScreen from "./components/TrackOrder";
import AdminDashboard from "./components/admin/AdminDashboard";
import AuthForm from "./components/auth/AuthScreen";
import FeedbackScreen from "./components/dashboard/Feedbacks/Feedback";
import UserDashboard from "./components/dashboard/UserDashboard";
import Homepage from "./components/home/homepage";
import Loading from "./components/util/Loading";






function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const userDoc = await getDoc(doc(firestore, "users", currentUser.uid));
        const userData = userDoc.data();
        setIsAdmin(userData?.sudo || false);
      } else {
        setUser(null);
        setIsAdmin(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="App">
      
        <Router>
          <Routes>
            <Route path="/" element={<Homepage user={user} />} />
            <Route path="/tracking" element={<TrackOrderScreen />} />
            <Route path="/feedbacks" element={<FeedbackScreen user={user} />} />
            {/* <Route path="/sheinTunisia" element={<SheinProductChecker />} /> */}
            <Route path="/u" element={<AuthForm />} />

            <Route
              path="/root"
              element={isAdmin ? <AdminDashboard /> : <AuthForm />}
            />

            <Route
              path="/me"
              element={
                user ? <UserDashboard UserClient={user} /> : <AuthForm />
              }
            />
          </Routes>
        </Router>
        <FloatingSupportButton />
      
    </div>
  );
}

export default App;
