// hooks/useAnnouncements.js
import { useState, useEffect } from 'react';
import { firestore } from '../config/firebaseConfig';
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore';

const useAnnouncements = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const announcementsRef = collection(firestore, 'announcements');
    const q = query(announcementsRef, orderBy('text')); // You might want to order by a different field if available

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedAnnouncements = querySnapshot.docs.map(doc => ({
        id: doc.id,
        text: doc.data().text,
        url: doc.data().url
      }));
      setAnnouncements(fetchedAnnouncements);
      setLoading(false);
    }, (error) => {
      console.error("Error fetching announcements:", error);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return { announcements, loading };
};

export default useAnnouncements;