import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { firestore } from '../../../config/firebaseConfig';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import {
  Typography,
  Grid,
  Box,
  Avatar,
  Snackbar,
  CircularProgress,
  useTheme
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import EditIcon from '@mui/icons-material/Edit';
import {
  ContentWrapper,
  StyledTextField,
  StyledPaper,
  StyledButton
} from '../Styles/User_Dashboard_Styles';
import { styled } from '@mui/material/styles';

// Only keep custom styles that aren't already in User_Dashboard_Styles
const ProfileHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(18),
  height: theme.spacing(18),
  border: `4px solid ${theme.palette.primary.main}`,
  boxShadow: theme.palette.mode === 'dark' 
    ? '0 4px 14px rgba(0, 0, 0, 0.4)' 
    : '0 4px 14px rgba(0, 0, 0, 0.15)',
  [theme.breakpoints.down('sm')]: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
}));

const UserProfile = ({ user, userData }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    address: '',
  });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    if (user && userData) {
      setFormData({
        fullName: userData.fullName || '',
        email: user.email || '',
        phoneNumber: userData.phoneNumber || '',
        address: userData.address || '',
      });
    }
  }, [user, userData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    if (!user) return;

    setLoading(true);
    try {
      const userDocRef = doc(firestore, 'users', user.uid);
      const updateData = {
        fullName: formData.fullName,
        phoneNumber: formData.phoneNumber,
        address: formData.address,
      };

      await updateDoc(userDocRef, updateData);
      const updatedUserDoc = await getDoc(userDocRef);
      const updatedUserData = updatedUserDoc.data();

      setFormData(prev => ({
        ...prev,
        ...updatedUserData
      }));

      setSnackbar({
        open: true,
        message: t('profile_updated_successfully'),
        severity: 'success'
      });
    } catch (error) {
      console.error('Error updating profile: ', error);
      setSnackbar({
        open: true,
        message: t('error_updating_profile'),
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  return (
    <ContentWrapper>
      <ProfileHeader>
        <StyledAvatar
          src={userData?.profilePicture || '/images/default-avatar.png'}
          alt={formData.fullName}
        />
        <Typography 
          variant="h4" 
          sx={{ 
            mt: 2, 
            fontWeight: 600,
            color: theme.palette.text.primary 
          }}
        >
          {formData.fullName}
        </Typography>
      </ProfileHeader>

      <form onSubmit={handleUpdateProfile}>
        <StyledPaper>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <StyledTextField
                fullWidth
                name="fullName"
                label={t('full_name')}
                value={formData.fullName}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                fullWidth
                name="email"
                label={t('email')}
                value={formData.email}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                fullWidth
                name="phoneNumber"
                label={t('phone')}
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                fullWidth
                name="address"
                label={t('address')}
                value={formData.address}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <StyledButton
              type="submit"
              disabled={loading}
              startIcon={loading ? <CircularProgress size={20} /> : <EditIcon />}
            >
              {loading ? t('updating') : t('update_profile')}
            </StyledButton>
          </Box>
        </StyledPaper>
      </form>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ 
            borderRadius: '8px',
            backgroundColor: theme.palette.mode === 'dark' 
              ? theme.palette.primary.dark 
              : theme.palette.primary.main
          }}
        >
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
    </ContentWrapper>
  );
};

export default UserProfile;