import {
  Tooltip,
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import {
  Brightness4 as Brightness4Icon,
  Brightness7 as Brightness7Icon,
  ExitToApp as ExitToAppIcon,
  Home as HomeIcon,
  Notifications as NotificationsIcon,
  Person as PersonIcon,
  Settings as SettingsIcon,
  ShoppingCart as ShoppingCartIcon,
} from "@mui/icons-material";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { onAuthStateChanged, signOut } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { auth } from "../../config/firebaseConfig";
import EmailNotVerified from "../auth/EmailNotVerified";
import { fetchUserData, listenForNotifications } from "./Firebase_Manager/Data_Manager";
import Home from "./Home/Home";
import Settings from "./Settings/Settings";
import { AppBarStyled, TabStyled, TabsStyled, getTheme } from "./Styles/User_Dashboard_Styles";
import UserOrders from "./orders/UserOrders";
import UserProfile from "./user/UserProfile";
import { t } from "i18next";

const UserDashboard = ({ UserClient }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [mode, setMode] = useState("light");
  const [notificationDialogOpen, setNotificationDialogOpen] = useState(false);
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        UserClient = user;

        const userDataFetched = await fetchUserData(user.uid);
        setUserData(userDataFetched);

        const unsubscribeNotifications = listenForNotifications(user.uid, (newNotifications) => {
          setNotifications(newNotifications);
          setUnreadNotifications(newNotifications.filter((n) => !n.read).length);
        });

        return () => unsubscribeNotifications();
      } else {
        setCurrentUser(null);
        setNotifications([]);
        setUnreadNotifications(0);
      }
    });

    return () => unsubscribeAuth();
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleLogoutClick = () => setLogoutDialogOpen(true);

  const handleLogoutConfirm = () => {
    signOut(auth)
      .then(() => setLogoutDialogOpen(false))
      .catch((error) => console.error("Error logging out:", error));
  };

  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  const handleNotificationClick = () => setNotificationDialogOpen(true);
  const handleNotificationDialogClose = () => setNotificationDialogOpen(false);

 
  

  const renderContent = () => {
    switch (selectedTab) {
      case 0:
        return <Home currentUserUid={currentUser?.uid} />;
      case 1:
        return <UserProfile user={currentUser} userData={userData} />;
      case 2:
        return <UserOrders customerUid={currentUser?.uid} />;
      case 3:
        return <Settings user={currentUser} userData={userData} />;
      default:
        return <Home currentUserUid={currentUser?.uid} />;
    }
  };

  const tabItems = [
    { label: t("home"), icon: <HomeIcon />, index: 0 },
    { label: t("my_profile"), icon: <PersonIcon />, index: 1 },
    { label: t("my_orders"), icon: <ShoppingCartIcon />, index: 2 },
    { label: t("settings"), icon: <SettingsIcon />, index: 3 },
  ];

  if (!UserClient.emailVerified) {
    return <EmailNotVerified />;
  }

  return (
    <ThemeProvider theme={getTheme(mode)}>
      <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh", alignItems: "center" }}>
        <CssBaseline />
        <AppBarStyled position="static">
          <Toolbar sx={{ justifyContent: "space-between", alignItems: "center" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Avatar
                src={userData?.profilePicture || "/images/default-avatar.png"}
                alt={userData?.fullName || t("guest")}
                sx={{ width: 40, height: 40, mr: 2 }}
                
              />
              
            </Box>

            <Typography variant="h6" component="div">
                {t("hello") + ", " + (userData?.fullName || t("guest"))}
              </Typography>
            <Box>
              <Tooltip title={t("notifications")}>
                <IconButton color="inherit" onClick={handleNotificationClick}>
                  <Badge badgeContent={unreadNotifications} color="secondary">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Tooltip title={mode === "dark" ? t("light_mode") : t("dark_mode")}>
                <IconButton onClick={toggleTheme} color="inherit">
                  {mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
              </Tooltip>
              <Tooltip title={t("logout")}>
                <IconButton color="inherit" onClick={handleLogoutClick}>
                  <ExitToAppIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
          <TabsStyled
            value={selectedTab}
            onChange={handleTabChange}
            variant={isMobile ? "scrollable" : "fullWidth"}
            scrollButtons={isMobile ? "auto" : false}
            centered={isMobile}
          >
            {tabItems.map((item) => (
              <TabStyled
                key={item.index}
                icon={item.icon}
                label={!isMobile && item.label}
                aria-label={item.label}
                iconPosition="top"
              />
            ))}
          </TabsStyled>
        </AppBarStyled>

        

        {/* Notification Dialog */}
        <Dialog
          open={notificationDialogOpen}
          onClose={handleNotificationDialogClose}
          PaperProps={{ style: { borderRadius: 12 } }}
        >
          <DialogTitle>{t("notifications")}</DialogTitle>
          <DialogContent>
            {notifications.length > 0 ? (
              notifications.map((notification) => (
                <Box key={notification.id} sx={{ marginBottom: 1 }}>
                  <Typography variant="body1">{notification.Content}</Typography>
                  <Typography variant="caption" color="textSecondary">
                    {new Date(notification.Notifications_Date.seconds * 1000).toLocaleString()}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography variant="body2">{t("no_notifications")}</Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleNotificationDialogClose}>{t("close")}</Button>
          </DialogActions>
        </Dialog>

        <Container component="main" sx={{ flexGrow: 1, py: 3 }}>
          {renderContent()}
        </Container>

        {/* Logout Confirmation Dialog */}
        <Dialog
          open={logoutDialogOpen}
          onClose={() => setLogoutDialogOpen(false)}
          PaperProps={{ style: { borderRadius: 12 } }}
        >
          <DialogTitle>{t("confirm_sign_out")}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t("sign_out_confirmation")}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setLogoutDialogOpen(false)}>{t("cancel")}</Button>
            <Button onClick={handleLogoutConfirm} autoFocus color="error">
              {t("yes")}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default UserDashboard;
