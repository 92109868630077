import { CardContent, Chip, Grid, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { loadNewsletterData } from './Data_Managements/Newsletter_data';
import { StyledBox, StyledCard, StyledTableCell, StyledTableRow, StyledTextField } from './Styles/GetTheme';


const NewsletterManager = () => {
  const [newsletter, setNewsletter] = useState([]);
  const [subs, setSubscribers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredNewsletter, setFilteredNewsletter] = useState([]);

  useEffect(() => {
    // Fetch transaction data on component mount
    const fetchData = async () => {
      try {
        const stats = await loadNewsletterData();
        setSubscribers (stats.totalmails);
        setNewsletter(stats.NewsLetter_Subs);
      } catch (error) {
        console.error("Error fetching Newsletter: ", error);
      }
    };
    fetchData();
  }, []);

  // Filter transactions by client name
  useEffect(() => {
    if (searchTerm) {
      setFilteredNewsletter(
        newsletter.filter(newsletter => 
          newsletter.email.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setFilteredNewsletter(newsletter);
    }
  }, [searchTerm, newsletter]);

  return (
    <StyledBox>
      <Typography variant="h4" gutterBottom>
        Newsletter Manager
      </Typography>
      
      {/* Search Field */}
      <StyledTextField
        label="Search by Email"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Transactions Table */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom fontWeight="bold">
                Recent Newsletter SUBS : {subs}
              </Typography>

              
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Email</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell> 
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredNewsletter.length > 0 ? (
                      filteredNewsletter.map((newsletter, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell>{newsletter.email}</StyledTableCell>
                          <StyledTableCell>
                            <Chip label="Followed" color="success" />
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell colSpan={5} align="center">
                          No newsleter subs found
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export default NewsletterManager;
