import { useState, useEffect } from 'react';
import { firestore } from '../config/firebaseConfig'; // Ensure correct import path
import { collection, onSnapshot, doc, getDoc, addDoc, updateDoc } from 'firebase/firestore';

const useFeedbacksWithUsers = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFeedbacks = async () => {
      setLoading(true);
      try {
        const feedbackCollectionRef = collection(firestore, 'feedback'); // Ensure the collection name is correct
        const unsubscribe = onSnapshot(feedbackCollectionRef, async (snapshot) => {
          const feedbacksData = [];
          for (const docSnapshot of snapshot.docs) {
            const feedback = docSnapshot.data();
            const userDocRef = doc(firestore, 'users', feedback.userId);
            try {
              const userDocSnapshot = await getDoc(userDocRef);
              const userData = userDocSnapshot.exists() ? userDocSnapshot.data() : {};
              feedbacksData.push({
                id: docSnapshot.id,
                ...feedback,
                user: userData
              });
            } catch (error) {
              console.error(`Error fetching user data for userId ${feedback.userId}:`, error);
            }
          }
          setFeedbacks(feedbacksData);
          setLoading(false);
        });

        return () => unsubscribe();
      } catch (error) {
        console.error('Error fetching feedbacks:', error);
        setLoading(false);
      }
    };

    fetchFeedbacks();
  }, []);

  const addFeedback = async (feedback) => {
    try {
      await addDoc(collection(firestore, 'feedback'), feedback);
      console.log('Feedback added successfully');
    } catch (error) {
      console.error('Error adding feedback:', error);
      console.error("Error adding feedback:", error); // Error log
    }
  };

  const updateFeedback = async (feedbackId, feedbackData) => {
    try {
      const feedbackDocRef = doc(firestore, 'feedback', feedbackId);
      await updateDoc(feedbackDocRef, feedbackData);
      console.log('Feedback updated successfully');
    } catch (error) {
      console.error('Error updating feedback:', error);
    }
  };

  return { feedbacks, loading, addFeedback, updateFeedback };
};

export default useFeedbacksWithUsers;
