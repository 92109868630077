import { firebaseFetchManager } from './Firebase Manager/fetch-data'; // Update with the correct path to your Firebase manager

const processShippings = (shippings) => {
  let totalShippings = shippings.length;
  let totalWeight = 0;
  let totalFees = 0;
  let monthlyShippings = {};
  let statusCounts = {};
  let recentShippings = []; // Initialize an array to hold recent shippings

  shippings.forEach(shipping => {
    const shippingWeight = shipping.shippingWeight || 0; // Ensure you use the correct property names
    const shippingFees = shipping.shippingFees || 0; // Ensure you use the correct property names
    const shippingDate = new Date(shipping.date.seconds * 1000);
    const monthYear = shippingDate.toLocaleString('default', { month: 'short', year: 'numeric' });

    // Summing total weight and fees
    totalWeight += shippingWeight;
    totalFees += shippingFees;

    // Monthly shipping statistics
    monthlyShippings[monthYear] = (monthlyShippings[monthYear] || 0) + shippingFees;

    // Status counts
    statusCounts[shipping.status] = (statusCounts[shipping.status] || 0) + 1;

    // Collect recent shippings
    recentShippings.push({
      shippingCompany: shipping.shippingCompany || 'Unknown',
      shippingDate: shippingDate.toLocaleDateString(),
      shippingWeight: shippingWeight,
      shippingFees: shippingFees,
      status: shipping.status || 'Unknown'
    });
  });

  return {
    totalShippings,
    totalWeight: totalWeight.toFixed(2),
    totalFees: totalFees.toFixed(2),
    averageWeight: (totalShippings > 0 ? (totalWeight / totalShippings).toFixed(2) : 0), // Prevent division by zero
    monthlyShippings: Object.entries(monthlyShippings).map(([month, amount]) => ({
      month,
      amount: parseFloat(amount.toFixed(2))
    })),
    statusCounts,
    recentShippings // Use the collected recent shippings array
  };
};

export const loadShippingData = async () => {
  try {
    console.log("Starting to fetch shipping data...");
    const { data: shippingsData } = await firebaseFetchManager.fetchShippings();

    console.log("Fetched shipping data:", shippingsData);

    // Process the shippings
    const shippingStats = processShippings(shippingsData);

    console.log("Processed shipping stats:", shippingStats);

    return shippingStats;
  } catch (error) {
    console.error("Error loading shipping data: ", error);
    throw error;
  }
};
