import React, { useState, useEffect } from 'react';
import { Typography, Button, TextField, Link, CircularProgress } from '@mui/material';
import { StyledBox, StyledCard, StyledTableCell, StyledTableRow } from './Styles/GetTheme';
import { loadSettingsData } from './Data_Managements/Settings_Data';

const SettingsManager = () => {
  const [settings, setSettings] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedSettings, setEditedSettings] = useState({});

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const settingsData = await loadSettingsData();
      setSettings(settingsData);
      setEditedSettings(settingsData);
    } catch (error) {
      console.error("Error fetching settings:", error);
      setError("Failed to load settings. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSave = async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Here you would typically call an API to save the settings
      // For now, we'll just update the local state
      setSettings(editedSettings);
      setEditMode(false);
    } catch (error) {
      console.error("Error saving settings:", error);
      setError("Failed to save settings. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setEditedSettings(settings);
    setEditMode(false);
  };

  const handleChange = (field) => (event) => {
    setEditedSettings({
      ...editedSettings,
      [field]: event.target.value
    });
  };

  if (isLoading) {
    return (
      <StyledBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </StyledBox>
    );
  }

  if (error) {
    return (
      <StyledBox>
        <Typography color="error">{error}</Typography>
        <Button variant="contained" color="primary" onClick={fetchSettings}>
          Retry
        </Button>
      </StyledBox>
    );
  }

  return (
    <StyledBox>
      <Typography variant="h4" gutterBottom>
        Settings Manager
      </Typography>

      <StyledCard>
        <StyledTableRow>
          <StyledTableCell>Exchange Rate (Taux)</StyledTableCell>
          <StyledTableCell>
            {editMode ? (
              <TextField
                value={editedSettings.Taux}
                onChange={handleChange('Taux')}
                type="number"
                step="0.01"
              />
            ) : (
              settings.Taux
            )}
          </StyledTableCell>
        </StyledTableRow>

        <StyledTableRow>
          <StyledTableCell>Alert Message</StyledTableCell>
          <StyledTableCell>
            {editMode ? (
              <TextField
                value={editedSettings.Alerte}
                onChange={handleChange('Alerte')}
                multiline
                fullWidth
              />
            ) : (
              settings.Alerte
            )}
          </StyledTableCell>
        </StyledTableRow>

        <StyledTableRow>
          <StyledTableCell>Alert Link</StyledTableCell>
          <StyledTableCell>
            {editMode ? (
              <TextField
                value={editedSettings.Link}
                onChange={handleChange('Link')}
                fullWidth
              />
            ) : (
              <Link href={settings.Link} target="_blank" rel="noopener noreferrer">
                {settings.Link}
              </Link>
            )}
          </StyledTableCell>
        </StyledTableRow>
      </StyledCard>

      <StyledBox sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        {editMode ? (
          <>
            <Button variant="contained" color="primary" onClick={handleSave} sx={{ mr: 1 }}>
              Save
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </>
        ) : (
          <Button variant="contained" color="primary" onClick={handleEdit}>
            Edit Settings
          </Button>
        )}
      </StyledBox>
    </StyledBox>
  );
};

export default SettingsManager;