import React, { useState, useEffect } from 'react';
import { Send, LogIn, HomeIcon } from 'lucide-react';
import Lottie from 'lottie-react';
import { Helmet } from 'react-helmet'; 
import loadingAnimation from '../../../assets/AJS_LOGO2.json';
import styles from './FeedbackScreen.module.css';
import useFeedbacks from '../../../hooks/useFeedbacks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import FeedbackEncourage from '../utils/Feedback_encourage';

const FeedbackScreen = ({ user }) => {
  const { feedbacks, loading, addFeedback, updateFeedback } = useFeedbacks(user?.uid); // Pass user.uid
  const { t } = useTranslation();
  const [newFeedback, setNewFeedback] = useState("");
  const [rating, setRating] = useState(0);
  const [editingFeedback, setEditingFeedback] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      const existingFeedback = feedbacks.find(fb => fb.userId === user.uid);
      if (existingFeedback) {
        setNewFeedback(existingFeedback.message);
        setRating(existingFeedback.rating);
        setEditingFeedback(existingFeedback);
      } else {
        setEditingFeedback(null);
      }
    }
  }, [user, feedbacks]);

  const handleGoLogin = () => {
    navigate('/u?action=feedbacks');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newFeedback.trim() && rating > 0) {
      const feedbackData = {
        message: newFeedback,
        rating: rating,
        timestamp: new Date(),
        userId: user.uid,
      };
      try {
        console.log("Form data:", feedbackData); // Debug log
        if (editingFeedback) {
          console.log("Updating existing feedback:", editingFeedback.id); // Debug log
          await updateFeedback(editingFeedback.id, feedbackData);
        } else {
          console.log("Adding new feedback"); // Debug log
          await addFeedback(feedbackData);
        }
        setNewFeedback("");
        setRating(0);
        setEditingFeedback(null);
        console.log("Feedback processed successfully"); // Debug log
      } catch (error) {
        console.error("Error processing feedback:", error); // Error log
      }
    }
  };

  const renderStars = (rating) => {
    return [...Array(5)].map((_, i) => (
      <span key={i} className={i < rating ? styles.starFilled : styles.starEmpty}>★</span>
    ));
  };

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <Lottie
          animationData={loadingAnimation}
          loop={true}
          className={styles.lottieAnimation}
        />
        <p>{t('view_feedbacks')}</p>
      </div>
    );
  }
  const calculateAverageRating = () => {
    if (feedbacks.length === 0) return 0;
    const sum = feedbacks.reduce((acc, feedback) => acc + feedback.rating, 0);
    return (sum / feedbacks.length).toFixed(1);
  };

  return (
    <div className={styles.container}>
      <Helmet>
        <title>{t('client_feedbacks')} - Amine Jameli Services</title>
      </Helmet>
      <div className={styles.card}>
        {/* New section for home page redirect */}
        <div className={styles.homeRedirect}>
          <button onClick={() => navigate('/')} className={styles.homeButton}>
            <HomeIcon size={18} className={styles.homeIcon} />
          </button>
        </div>

        <h1 className={styles.title}>{t('client_feedbacks')}</h1>
        {user ? (
          <form onSubmit={handleSubmit} className={styles.feedbackForm}>
            <h2 className={styles.sectionTitle}>
              {editingFeedback ? t('edit_your_feedback') : t('share_your_thoughts')}
            </h2>
            <textarea
              value={newFeedback}
              onChange={(e) => setNewFeedback(e.target.value)}
              className={styles.textarea}
              rows="4"
              placeholder={t('enter_feedback')}
            ></textarea>
            <div className={styles.formFooter}>
              <div className={styles.starRating}>
                {[1, 2, 3, 4, 5].map((star) => (
                  <button
                    key={star}
                    type="button"
                    onClick={() => setRating(star)}
                    className={`${styles.starButton} ${star <= rating ? styles.starFilled : styles.starEmpty}`}
                  >
                    ★
                  </button>
                ))}
              </div>
              <button type="submit" className={styles.submitButton}>
                <Send size={18} className={styles.sendIcon} />
                {editingFeedback ? t('update_feedback') : t('add_feedback')}
              </button>
            </div>
          </form>
        ) : (
          <div className={styles.notLoggedInContainer}>
            <h2 className={styles.sectionTitle}>{t('join_the_conversation')}</h2>
            <p className={styles.notLoggedInText}>{t('login_to_share_feedback')}</p>
            <button onClick={handleGoLogin} className={styles.loginButton}>
              <LogIn size={18} className={styles.loginIcon} />
              {t('login_to_give_feedback')}
            </button>
          </div>
        )}

        <div className={styles.feedbackListContainer}>
          <h2 className={styles.sectionTitle}>{t('client_feedbacks')}</h2>
          <div className={styles.feedbackStats}>
            <p className={styles.feedbackCount}>{feedbacks.length} {t('feedbacks')}</p>
            <div className={styles.averageRating}>
              <span className={styles.starFilled}>★</span>
              <span>{calculateAverageRating()}</span>
            </div>
          </div>
          <div className={styles.feedbackList}>
            {feedbacks.map((feedback) => (
              <div key={feedback.id} className={styles.feedbackItem}>
                <p className={styles.feedbackText}>{feedback.message}</p>
                <div className={styles.feedbackMeta}>
                  <div className={styles.starRating}>
                    {renderStars(feedback.rating)}
                  </div>
                  <p className={styles.userName}>{feedback.user?.fullName || t('anonymous')}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <FeedbackEncourage amount={10} />
    </div>
  );
};

export default FeedbackScreen;
