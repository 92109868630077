import React, { useState, useEffect, useRef } from 'react';
import { firestore } from '../../../config/firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './what-buy.css';

const WhatToBuySection = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const scrollRef = useRef(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'WhatToBuy'));
        const urls = querySnapshot.docs.map(doc => doc.data().attachmentUrl);
        console.log(urls.length);
        setImageUrls(urls);
      } catch (error) {
        console.error('Error fetching images: ', error);
      }
    };

    fetchImages();
  }, []);

  const handleClickOpen = (url) => {
    setSelectedImage(url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage('');
  };

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -scrollRef.current.clientWidth, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: scrollRef.current.clientWidth, behavior: 'smooth' });
    }
  };

  return (
    <section className="what_to_buy_section">
      <div className="heading_container">
        <h2>ماذا يمكن ان اشترى</h2>
      </div>
      <div className="product_scroll_container">
        <button className="scroll_button left" onClick={scrollLeft}>‹</button>
        <div className="product_scroll" ref={scrollRef}>
          {imageUrls.map((url, index) => (
            <div key={index} className="product_item" onClick={() => handleClickOpen(url)}>
              <img src={url} alt={`Product ${index}`} className="product_image" />
            </div>
          ))}
        </div>
        <button className="scroll_button right" onClick={scrollRight}>›</button>
      </div>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <img src={selectedImage} alt="Selected Product" className="dialog_image" />
        </DialogContent>
      </Dialog>
    </section>
  );
};

export default WhatToBuySection;
