import { AttachMoney as AttachMoneyIcon, AttachMoneySharp, CalendarToday as CalendarTodayIcon, LocalShipping as LocalShippingIcon, ShoppingCart as ShoppingCartIcon } from '@mui/icons-material';
import { Box, Card, CardContent, CircularProgress, Grid, Tooltip, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchUserStatistics } from '../Firebase_Manager/Data_Manager';
import { ContentWrapper } from '../Styles/User_Dashboard_Styles';

const StatCard = ({ icon: Icon, title, value }) => {
  const { t } = useTranslation();
  
  return (
    <Grid item xs={12} sm={6} md={3}>
      <Tooltip title={t(`${title}_tooltip`)} placement="top" arrow>
        <Card component={motion.div}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <CardContent>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Icon color="primary" style={{ fontSize: 40, marginBottom: 8 }} />
              <Typography variant="h6" component="div">
                {t(title)}
              </Typography>
              <Typography variant="h4" component="div">
                {value}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Tooltip>
    </Grid>
  );
};

const Home = ({ currentUserUid }) => {
  const { t } = useTranslation();
  const [statistics, setStatistics] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getStatistics = async () => {
      try {
        const stats = await fetchUserStatistics(currentUserUid);
        setStatistics(stats);
      } catch (error) {
        console.error('Error fetching statistics:', error);
      } finally {
        setLoading(false); // Set loading to false after data fetch attempt
      }
    };

    getStatistics();
  }, [currentUserUid]);

  const statCards = useMemo(() => [
    { icon: ShoppingCartIcon, title: 'total_orders', value: statistics?.totalOrders },
    { icon: AttachMoneyIcon, title: 'total_spent_tnd', value: statistics?.totalPriceInTnd?.toFixed(2) },
    { icon: AttachMoneySharp, title: 'total_reste_too_pay', value: statistics?.totalResteToPay?.toFixed(2)  },
    { icon: LocalShippingIcon, title: 'orders_delivered', value: statistics?.ordersDelivered },
    { icon: CalendarTodayIcon, title: 'last_order_date', value: statistics?.lastOrderDate ? new Date(statistics.lastOrderDate.toDate()).toLocaleDateString() : t('no_orders_found') },
  ], [statistics, t]);

  return (
    <ContentWrapper>
      <Typography variant="h4" gutterBottom>
        {t('client_dashboard')}
      </Typography>
      {/* Conditionally render CircularProgress if loading is true */}
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
          <CircularProgress size={50} /> {/* You can customize the size here */}
        </Box>
      ) : (
        /* Render Grid with StatCards once loading is false and statistics are available */
        <Grid container spacing={3}>
          {statCards.map((card) => (
            <StatCard key={card.title} {...card} />
          ))}
        </Grid>
      )}
    </ContentWrapper>
  );
};

export default Home;
