import { collection, getDocs, limit, orderBy, query, startAfter, doc, addDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { firestore } from '../../../../config/firebaseConfig';

const CACHE_EXPIRY = 5 * 60 * 1000; // 5 minutes in milliseconds
const PAGE_SIZE = 500;

class FirebaseFetchManager {
  constructor() {
    this.cache = {};
  }

  async fetchWithCacheAndPagination(collectionName, lastDocSnapshot = null, orderByField = null) {
    // Check cache first
    const cachedData = this.getCachedData(collectionName);
    if (cachedData && !lastDocSnapshot) {
      return { data: cachedData, lastDoc: null, hasMore: true };
    }

    // Prepare Firestore query
    let q = query(collection(firestore, collectionName));
    
    if (orderByField) {
      q = query(q, orderBy(orderByField, 'desc'));
    }
    
    if (lastDocSnapshot) {
      q = query(q, startAfter(lastDocSnapshot));
    }
    
    q = query(q, limit(PAGE_SIZE));

    console.log(`Fetching ${collectionName} with query:`, q);

    try {
      const querySnapshot = await getDocs(q);
      console.log(`Fetched ${querySnapshot.docs.length} documents from ${collectionName}`);

      const fetchedData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      console.log(`Processed data from ${collectionName}:`, fetchedData);

      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      const hasMore = querySnapshot.docs.length === PAGE_SIZE;

      // Update cache if it's the first page
      if (!lastDocSnapshot) {
        this.setCachedData(collectionName, fetchedData);
      }

      return { data: fetchedData, lastDoc, hasMore };
    } catch (error) {
      console.error(`Error fetching data from ${collectionName}:`, error);
      throw error;
    }
  }

  getCachedData(collectionName) {
    const cachedItem = this.cache[collectionName];
    if (cachedItem && Date.now() - cachedItem.timestamp < CACHE_EXPIRY) {
      return cachedItem.data;
    }
    return null;
  }

  setCachedData(collectionName, data) {
    this.cache[collectionName] = {
      data,
      timestamp: Date.now()
    };
  }

  async addDocument(collectionName, data) {
    try {
      const docRef = await addDoc(collection(firestore, collectionName), data);
      console.log(`Document added to ${collectionName} with ID: ${docRef.id}`);
      return docRef.id;
    } catch (error) {
      console.error(`Error adding document to ${collectionName}:`, error);
      throw error;
    }
  }

  async updateDocument(collectionName, docId, data) {
    try {
      const docRef = doc(firestore, collectionName, docId);
      await updateDoc(docRef, data);
      console.log(`Document updated in ${collectionName} with ID: ${docId}`);
    } catch (error) {
      console.error(`Error updating document in ${collectionName}:`, error);
      throw error;
    }
  }

  async deleteDocument(collectionName, docId) {
    try {
      await deleteDoc(doc(firestore, collectionName, docId));
      console.log(`Document deleted from ${collectionName} with ID: ${docId}`);
    } catch (error) {
      console.error(`Error deleting document from ${collectionName}:`, error);
      throw error;
    }
  }

  async fetchUsers(lastDocSnapshot = null) {
    return this.fetchWithCacheAndPagination('users', lastDocSnapshot)
  }

  // async toggleEmailVerification(userId, currentStatus) {
  //   try {
  //     const userRef = doc(firestore, 'users', userId);
  //     await updateDoc(userRef, {
  //       emailVerified: !currentStatus
  //     });
  //     console.log(`User ${userId} email verification status updated to: ${!currentStatus}`);
  //     return true;
  //   } catch (error) {
  //     console.error("Error updating user verification status:", error);
  //     throw error;
  //   }
  // }

  async fetchOrders(lastDocSnapshot = null) {
    return this.fetchWithCacheAndPagination('orders', lastDocSnapshot, 'orderDate');
  }

  async fetchShippings(lastDocSnapshot = null) {
    return this.fetchWithCacheAndPagination('shippings', lastDocSnapshot, 'date');
  }

  async fetchNewsletter(lastDocSnapshot = null) {
    return this.fetchWithCacheAndPagination('newsletter', lastDocSnapshot )
  }

  async fetchFeedbacks(lastDocSnapshot = null) {
    return this.fetchWithCacheAndPagination('feedback', lastDocSnapshot )
  }
  
  async fetchAnnouncements(lastDocSnapshot = null) {
    return this.fetchWithCacheAndPagination('announcements', lastDocSnapshot);
  }

  async addAnnouncement(announcementData) {
    return this.addDocument('announcements', announcementData);
  }

  async updateAnnouncement(announcementId, announcementData) {
    return this.updateDocument('announcements', announcementId, announcementData);
  }

  async deleteAnnouncement(announcementId) {
    return this.deleteDocument('announcements', announcementId);
  }

  // Taux
  async fetchTaux(lastDocSnapshot = null) {
    return this.fetchWithCacheAndPagination('taux', lastDocSnapshot);
  }

  async addTaux(tauxData) {
    return this.addDocument('taux', tauxData);
  }

  async updateTaux(tauxId, tauxData) {
    return this.updateDocument('taux', tauxId, tauxData);
  }

  async deleteTaux(tauxId) {
    return this.deleteDocument('taux', tauxId);
  }

  
}

export const firebaseFetchManager = new FirebaseFetchManager();