import React from 'react';
import { useTranslation } from 'react-i18next';
import './Service.css'; // Ensure this path is correct

const Services = () => {
  const { t } = useTranslation();

  return (
    <div>
      <section className="service_section layout_padding" id="services">
        <div className="container">
          <div className="heading_container">
            <h2>{t('our_services')}</h2>
            <p>{t('we_ship_to_you_products_from')}</p>
          </div>
          <div className="row">
            {[
              { src: 'shein.png', alt: 'shein_alt_text', name: 'shein', description: 'shein_description', link: 'https://de.shein.com' },
              { src: 'temu.png', alt: 'temu_alt_text', name: 'temu', description: 'temu_description', link: 'https://www.temu.com/' },
              { src: 'amazon.png', alt: 'amazon_alt_text', name: 'amazon', description: 'amazon_description', link: 'https://amazon.com' },
              { src: 'aliexpress.png', alt: 'aliexpress_alt_text', name: 'aliexpress', description: 'aliexpress_description', link: 'https://ar.aliexpress.com/' },
              { src: 'hm.png', alt: 'hm_alt_text', name: 'H&M', description: 'hm_description', link: 'https://www2.hm.com/de_de/index.html' },
              { src: 'zalando.png', alt: 'zalando_alt_text', name: 'zalando', description: 'zalando_description', link: 'https://en.zalando.de/' }
            ].map((service, index) => (
              <div key={index} className="col-md-6 col-lg-3">
                <div className="box">
                  <div className="img-box">
                    <img src={`images/${service.src}`} alt={t(service.alt)} />
                  </div>
                  <div className="detail-box">
                    <p>{t(service.description)}</p>
                    <a href={service.link} aria-label={t(`visit_${service.name}`)}>
                      <span>{t('visit')}</span>
                      <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* Uncomment if needed */}
          {/* <div className="btn-box">
            <a href="">{t('view_more')}</a>
          </div> */}
        </div>
      </section>
    </div>
  );
};

export default Services;
