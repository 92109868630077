import React, { useState } from 'react';
import './faq.css';

const FAQ = () => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const faqData = [
    {
      question: 'ما هو Amine Jameli Services؟',
      answer: 'Amine Jameli Services هو خدمة شحن دولية تساعد العملاء في تونس على شراء المنتجات من المواقع العالمية واستلامها في تونس.',
    },
    {
      question: 'كيف يمكنني تتبع طلبي؟',
      answer: 'يمكنك تتبع طلبك من خلال تطبيقنا باستخدام الرقم المرجعي الذي ستتلقاه بعد الشراء.',
    },
    {
      question: 'ما هي طرق الدفع المتاحة؟',
      answer: 'نحن نقبل الدفع عبر التحويل البنكي والدفع الإلكتروني (D17 , Flouci ).',
    },
    {
        "question": "كيف يتم دفع المبلغ ؟",
        "answer": "نقوم بتحصيل 50% من المبلغ في الدفعة الأولى، والباقي يتم دفعه عند التسليم."
      },
      
    {
      question: 'هل يمكنني إلغاء طلبي؟',
      answer: 'نعم، يمكنك إلغاء طلبك قبل أن يتم شحنه. بعد الشحن، لا يمكن إلغاء الطلب.',
    },
    {
      question: 'كم من الوقت يستغرق وصول الطرد إلى تونس؟',
      answer: 'عادةً ما يستغرق الطرد من 20 إلى 30 يومًا للوصول إلى تونس، حسب الموقع الذي تم الشراء منه.',
    },
    {
        "question": "ما هي تكلفة الشحن داخل تونس؟",
        "answer": "تكلفة الشحن داخل تونس هي 8 دينار تونسي لجميع الولايات الـ 24. لكن في بعض الأحيان نقدم الشحن المجاني لك."
      },
      
  ];

  const toggleQuestion = (index) => {
    setSelectedQuestion(selectedQuestion === index ? null : index);
  };

  return (
    <section className="client_section" id='faqs'>
    <div className="faq-container">
      <h2 className="faq-title">الأسئلة المتكررة</h2>
      <ul className="faq-list">
        {faqData.map((item, index) => (
          <li
            key={index}
            className={`faq-item ${
              selectedQuestion === index ? 'active' : ''
            }`}
          >
            <div
              className="faq-question"
              onClick={() => toggleQuestion(index)}
            >
              {item.question}
            </div>
            {selectedQuestion === index && (
              <div className="faq-answer">{item.answer}</div>
            )}
          </li>
        ))}
      </ul>
    </div>
    </section>
  );
};

export default FAQ;
