import React, { useState } from 'react';
import { Box, Fab, Tooltip, List, ListItem, ListItemIcon, ListItemText, Zoom } from '@mui/material';
import { Facebook, Instagram, WhatsApp, Language, Telegram } from '@mui/icons-material';

const FloatingSupportButton = () => {
  const [open, setOpen] = useState(false);

  const socialMediaLinks = [
    { url: 'https://www.facebook.com/profile.php?id=61564908999870', icon: <Facebook />, name: 'Facebook' },
    { url: 'https://www.instagram.com/amine.jameli.services/', icon: <Instagram />, name: 'Instagram' },
    { url: 'https://wa.me/21628182762', icon: <WhatsApp />, name: 'WhatsApp' },
    { url: 'https://t.me/AmineJameliServices', icon: <Telegram />, name: 'Telegram' },
    { url: 'https://price.aminejameli.com', icon: <Language />, name: 'Price Finder' }
  ];

  const handleSupportClick = () => {
    setOpen(!open);
  };

  const handleLinkClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Box>
      {open && (
        <Zoom in={open}>
          <Box
            sx={{
              position: 'fixed',
              bottom: '90px',
              right: '20px',
              backgroundColor: 'white',
              borderRadius: '16px',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
              zIndex: 1001,
              padding: '10px',
            }}
          >
            <List>
              {socialMediaLinks.map((link, index) => (
                <ListItem
                  button
                  key={index}
                  onClick={() => handleLinkClick(link.url)}
                  sx={{
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      backgroundColor: 'orange',
                      color: 'white',
                      transform: 'scale(1.05)',
                      '& .MuiListItemIcon-root': {
                        color: 'red',
                      },
                    },
                    marginBottom: '8px',
                    borderRadius: '12px',
                  }}
                >
                  <ListItemIcon sx={{ color: 'orange', minWidth: '40px' }}>{link.icon}</ListItemIcon>
                  <ListItemText primary={link.name} sx={{ color: 'black' }} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Zoom>
      )}
      <Tooltip title="Support" placement="left">
        <Fab
          onClick={handleSupportClick}
          sx={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            backgroundColor: 'orange',
            color: 'white',
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: 'red',
              transform: 'scale(1.1)',
            },
            width: '60px',
            height: '60px',
            zIndex: 1000,
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
          }}
        >
          💬
        </Fab>
      </Tooltip>
    </Box>
  );
};

export default FloatingSupportButton;
