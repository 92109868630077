import { firebaseFetchManager } from './Firebase Manager/fetch-data'; // Adjust the path as needed

const processUsers = (users) => {
  return users.map(user => ({
    id: user.uid,
    fullName: user.fullName || 'Unknown',
    email: user.email || 'No Email',
    phoneNumber: user.phoneNumber || 'No Phone Number',
    points: user.points || 0,
    role: user.role || 'User',
    // emailVerified: user.emailVerified || false,
    // createdAt: user.createdAt ? new Date(user.createdAt.seconds * 1000).toLocaleString() : 'Unknown'
  }));
};

export const loadUserData = async (lastDocSnapshot = null) => {
  try {
    console.log("Starting to fetch user data...");
    const usersData = await firebaseFetchManager.fetchUsers(lastDocSnapshot);
    console.log("Fetched user data:", usersData);

    // Process the users
    const usersStats = processUsers(usersData.data);
    console.log("Processed user stats:", usersStats);

    return {
      users: usersStats,
      lastDoc: usersData.lastDoc,
      hasMore: usersData.hasMore
    };
  } catch (error) {
    console.error("Error loading user data: ", error);
    throw error;
  }
};

// export const toggleEmailVerification = async (userId, currentStatus) => {
//   try {
//     const success = await firebaseFetchManager.toggleEmailVerification(userId, currentStatus);
//     if (success) {
//       console.log(`Email verification toggled successfully for user ${userId}`);
//       return true;
//     }
//     return false;
//   } catch (error) {
//     console.error("Error updating email verification status", error);
//     throw error;
//   }
// };

// export const loadEmailVerificationStatuses = async () => {
//   try {
//     console.log("Starting to fetch email verification statuses...");
//     const statuses = await firebaseFetchManager.fetchEmailVerificationStatuses();
//     console.log("Fetched email verification statuses:", statuses);
//     return statuses;
//   } catch (error) {
//     console.error("Error loading email verification statuses: ", error);
//     throw error;
//   }
// };