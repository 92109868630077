import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';

// Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBwHnkuJMl6wT9_XacO97LNhz5QHOPef8Q',
  authDomain: 'aminejameliservices.firebaseapp.com',
  projectId: 'aminejameliservices',
  storageBucket: 'aminejameliservices.appspot.com',
  messagingSenderId: '815526979960',
  appId: '1:815526979960:web:933c85d8b1061d6f1b008d',
  measurementId: 'G-FCJ74METBV',
  databaseURL: 'https://aminejameliservices-default-rtdb.europe-west1.firebasedatabase.app',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Firebase services
export const auth = getAuth(app);
export const database = getDatabase(app);
export const storage = getStorage(app);
export const firestore = getFirestore(app);

export default app;
