import {
  Brightness4 as Brightness4Icon,
  Brightness7 as Brightness7Icon,
  Money,
  People as PeopleIcon,
  ShoppingCart as ShoppingCartIcon
} from '@mui/icons-material';
import {
  AppBar,
  Box,
  CardContent,
  Chip,
  CssBaseline,
  Grid,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, TableRow,
  Tabs,
  ThemeProvider,
  Toolbar,
  Typography,
  useMediaQuery
} from '@mui/material';
import { ChartNoAxesCombined, DatabaseBackup, Euro, FolderSync, HandCoins, Landmark, LayoutDashboard, Mail, MailCheck, Package, ReceiptEuro, SlidersHorizontal, Truck, User, Weight } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis, YAxis
} from 'recharts';

import { IconBox, StyledBox, StyledCard, getTheme } from './Styles/GetTheme';



// Import your tab components

import { loadStats } from './Data_Managements/Stats_data';
import BackupManager from './ManageBackup';
import NewsletterManager from './ManageNewsletter';
import OrdersManager from './ManageOrders';
import SettingsManager from './ManageSettings';
import ShippingsManager from './ManageShipping';
import UsersManager from './ManageUsers';
import RapportManager from './RapportManager';
import EmailTest from './TestMail';
import TransactionsManager from './TransactionsManager';

// Create a custom theme



const ModernAdminDashboard = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = useState(prefersDarkMode ? 'dark' : 'light');
  const theme = React.useMemo(() => getTheme(mode), [mode]);
  const [recentOrders, setRecentOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState(null);

  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };


  useEffect(() => {
    const fetchStats = async () => {
      try {
        const data = await loadStats();
        setStats(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!stats) return null;
  

  const COLORS = [theme.palette.primary.main, theme.palette.secondary.main, theme.palette.error.main, theme.palette.warning.main, theme.palette.info.main];

  const tabItems = [
    { icon: <LayoutDashboard />, label: 'Dashboard' },
    { icon: <Package />, label: 'Order' },
    { icon: <Money />, label: 'Transactions' },
    { icon: <User />, label: 'Users' },
    { icon: <Truck />, label: 'Shippings' },
    { icon: <Mail />, label: 'Newsletter' },
    { icon: <FolderSync />, label: 'Reports' },
    { icon: <DatabaseBackup />, label: 'Back Up' },
    { icon: <SlidersHorizontal />, label: 'Settings' },
    { icon: <MailCheck />, label: 'Mailing' },

    // { icon: <ShippingIcon />, label: 'Shipping' },
    // { icon: <PeopleIcon />, label: 'Users' },
    // { icon: <EmailIcon />, label: 'Newsletter' },
    // { icon: <AssessmentIcon />, label: 'Reports' },
  ];

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ flexGrow: 1, bgcolor: 'background.default', minHeight: '100vh', width: '100vw', overflowX: 'hidden' }}>
        <AppBar position="static" elevation={0}>
          <Toolbar>
            {/* Tabs Section */}
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="secondary"
              textColor="inherit"
              sx={{
                flexGrow: 1,
                '& .MuiTab-root': {
                  minWidth: { xs: '80px', sm: '120px' },
                  fontSize: { xs: '0.7rem', sm: '0.9rem' },
                  fontWeight: 'bold',
                  color: '#FFF',
                  opacity: 0.7,
                  '&.Mui-selected': {
                    opacity: 1,
                  },
                },
              }}
            >
              {tabItems.map((item, index) => (
                <Tab key={index} icon={item.icon} label={item.label} />
              ))}
            </Tabs>

            {/* Theme Switch Button */}
            <IconButton color="inherit" onClick={toggleTheme}>
              {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
          </Toolbar>
        </AppBar>


        <StyledBox>
          {tabValue === 0 && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom fontWeight="bold" color="primary">
                  Business Overview
                </Typography>
              </Grid>

              {/* Stats Cards */}
              <Grid item xs={12} container spacing={2}>
                {[
                  { title: 'Total Users', value: stats.totalUsers, icon: <PeopleIcon />, color: theme.palette.primary.light },
                  { title: 'Total Orders', value: stats.totalOrders, icon: <ShoppingCartIcon />, color: theme.palette.secondary.light },
                  { title: 'Total Paid In Tnd ', value: `${stats.totalPaidInTnd} TND`, icon: <Landmark />, color: theme.palette.success.light },
                  { title: 'Total Paid In Eur', value: `${stats.totalPaidInEur} EUR `, icon: <Euro />, color: theme.palette.success.light },
                  { title: 'Total Revenue', value: `${stats.totalRevenue} TND`, icon: <ChartNoAxesCombined />, color: theme.palette.success.light },
                  { title: 'Unpaid Amount', value: `${stats.totalUnpaidAmount} TND`, icon: <HandCoins />, color: theme.palette.error.light },
                  { title: 'Shippings', value: stats.totalShippings, icon: <Truck />, color: theme.palette.error.light },

                  // Adding total shipping fees
                  { title: 'Total Shipping Fees', value: `${stats.totalShippingFees} TND`, icon: <ReceiptEuro />, color: theme.palette.info.light },

                  // Adding total shipping weight
                  { title: 'Total Shipping Weight', value: `${stats.totalShippingWeight} kg`, icon: <Weight />, color: theme.palette.info.light }
                ]
                  .map((item, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                      <StyledCard>
                        <CardContent>
                          <Box display="flex" alignItems="center">
                            <IconBox color={item.color}>{item.icon}</IconBox>
                            <Box ml={2}>
                              <Typography variant="subtitle1" color="textSecondary">{item.title}</Typography>
                              <Typography variant="h5" fontWeight="bold">{item.value}</Typography>
                            </Box>
                          </Box>
                        </CardContent>
                      </StyledCard>
                    </Grid>
                  ))}
              </Grid>

              {/* Charts */}
              <Grid item xs={12} md={8}>
                <StyledCard>
                  <CardContent>
                    <Typography variant="h6" gutterBottom fontWeight="bold" textAlign="center">
                      Monthly Revenue
                    </Typography>
                    <ResponsiveContainer width="100%" height={320}>
                      <AreaChart data={stats.monthlyRevenue}>
                        <defs>
                          <linearGradient id="colorRevenue" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={theme.palette.primary.main} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={theme.palette.primary.main} stopOpacity={0} />
                          </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" stroke={theme.palette.divider} />
                        <XAxis dataKey="month" stroke={theme.palette.text.secondary} />
                        <YAxis stroke={theme.palette.text.secondary} />
                        <Tooltip
                          contentStyle={{
                            backgroundColor: theme.palette.background.paper,
                            color: theme.palette.text.primary,
                            borderRadius: '8px',
                            boxShadow: theme.shadows[3],
                          }}
                        />
                        <Area
                          type="monotone"
                          dataKey="revenue"
                          stroke={theme.palette.primary.main}
                          fillOpacity={1}
                          fill="url(#colorRevenue)"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </CardContent>
                </StyledCard>
              </Grid>

              <Grid item xs={12} md={4}>
                <StyledCard>
                  <CardContent>
                    <Typography variant="h6" gutterBottom fontWeight="bold" textAlign="center">
                      Order Status
                    </Typography>
                    <ResponsiveContainer width="100%" height={320}>
                      <PieChart>
                        <Pie
                          data={stats.orderStatusDistribution}
                          cx="50%"
                          cy="50%"
                          innerRadius={70}
                          outerRadius={90}
                          fill="#FF7043"
                          paddingAngle={3}
                          dataKey="value"
                        >
                          {stats.orderStatusDistribution.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip
                          contentStyle={{
                            backgroundColor: theme.palette.background.paper,
                            color: theme.palette.text.primary,
                            borderRadius: '8px',
                            boxShadow: theme.shadows[3],
                          }}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                    <Box mt={2}>
                      {stats.orderStatusDistribution.map((entry, index) => (
                        <Box key={index} display="flex" alignItems="center" mb={1}>
                          <Box
                            width={16}
                            height={16}
                            borderRadius="50%"
                            bgcolor={COLORS[index % COLORS.length]}
                            mr={1}
                          />
                          <Typography variant="body2">
                            {entry.name}: {entry.value}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </CardContent>
                </StyledCard>
              </Grid>

              <Grid item xs={12} md={6}>
                <StyledCard
                  sx={{
                    borderRadius: '12px',
                    padding: '5px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.02)',
                      boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)',
                    },
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" gutterBottom fontWeight="bold" textAlign="center">
                      Top Selling Sites
                    </Typography>
                    <ResponsiveContainer width="100%" height={300}>
                      <BarChart data={stats.topSellingSites} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" tick={{ fill: "#555" }} />
                        <YAxis tick={{ fill: "#555" }} />
                        <Tooltip
                          contentStyle={{
                            backgroundColor: "#333",
                            color: "#fff",
                            borderRadius: "5px",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                          }}
                        />
                        <Bar dataKey="sales" fill="url(#gradientSales)" radius={[10, 10, 0, 0]}>
                          {stats.topSellingSites.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={theme.palette.primary.main}
                              strokeWidth={2}
                              stroke="rgba(0,0,0,0.2)"
                              style={{ filter: 'drop-shadow(0px 2px 4px rgba(0,0,0,0.2))' }} // Shadow effect
                            />
                          ))}
                        </Bar>
                        <defs>
                          <linearGradient id="gradientSales" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={theme.palette.primary.main} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={theme.palette.primary.main} stopOpacity={0.3} />
                          </linearGradient>
                        </defs>
                      </BarChart>
                    </ResponsiveContainer>
                  </CardContent>
                </StyledCard>
              </Grid>

              <Grid item xs={12} md={6}>
                <StyledCard
                  sx={{
                    borderRadius: '12px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.02)',
                      boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)',
                    },
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" gutterBottom fontWeight="bold" textAlign="center">
                      Top Payment Methods by Amount
                    </Typography>
                    <ResponsiveContainer width="100%" height={300}>
                      <BarChart data={stats.topPaymentMethods} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" tick={{ fill: "#555" }} />
                        <YAxis tick={{ fill: "#555" }} />
                        <Tooltip
                          contentStyle={{
                            backgroundColor: "#333",
                            color: "#fff",
                            borderRadius: "5px",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                          }}
                        />
                        <Bar dataKey="amount" fill="url(#gradientAmount)" radius={[10, 10, 0, 0]}>
                          {stats.topPaymentMethods.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={theme.palette.secondary.main}
                              strokeWidth={2}
                              stroke="rgba(0,0,0,0.2)"
                              style={{ filter: 'drop-shadow(0px 2px 4px rgba(0,0,0,0.2))' }} // Shadow effect
                            />
                          ))}
                        </Bar>
                        <defs>
                          <linearGradient id="gradientAmount" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={theme.palette.secondary.main} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={theme.palette.secondary.main} stopOpacity={0.3} />
                          </linearGradient>
                        </defs>
                      </BarChart>
                    </ResponsiveContainer>
                  </CardContent>
                </StyledCard>
              </Grid>

              {/* Recent Orders */}
             <Grid item xs={12}>
                <StyledCard>
                  <CardContent>
                    <Typography variant="h6" gutterBottom fontWeight="bold">
                      Recent Orders
                    </Typography>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Order Code</TableCell>
                            <TableCell>Client Name</TableCell>
                            <TableCell>Order Date</TableCell>
                            <TableCell>Profit (TND)</TableCell>
                            <TableCell>Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {stats.recentOrders.map((order) => (
                            <TableRow key={order.id}>
                              <TableCell>{order.orderCode}</TableCell>
                              <TableCell>{order.customerName}</TableCell>
                              <TableCell>{order.orderDate.toDate().toLocaleDateString()}</TableCell>
                              <TableCell>{order.profitInTnd}</TableCell>
                              <TableCell>
                                <Chip
                                  label={order.status}
                                  color={order.status === 'Completed' ? 'success' : 'warning'}
                                  size="small"
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </StyledCard>
              </Grid> 
            </Grid>
          )}
          {tabValue === 1 && <OrdersManager  />}
          {tabValue === 2 && <TransactionsManager />}
          {tabValue === 3 && <UsersManager />}
          {tabValue === 4 && <ShippingsManager />}
          {tabValue === 5 && <NewsletterManager />}
           {tabValue === 6 && <RapportManager />} 
          {tabValue === 7 && <BackupManager />}
          {tabValue === 8 && <SettingsManager />}
          {tabValue === 9 && <EmailTest />}

          {/* {tabValue === 2 && <ManageShipping />}
          {tabValue === 3 && <ManageUsers />}
          {tabValue === 4 && <NewsletterEmails />}
          {tabValue === 5 && <ManageRapport />} */}
        </StyledBox>
      </Box>
    </ThemeProvider>
  );
};

export default ModernAdminDashboard;