import {
  ContentCopy as ContentCopyIcon,
  Info as InfoIcon,
  Language as LanguageIcon,
  LockReset as LockResetIcon
} from '@mui/icons-material';
import {
  Alert,
  Box,
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { sendPasswordResetEmail } from 'firebase/auth';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { auth } from '../../../config/firebaseConfig';

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[3],
  '&:hover': {
    boxShadow: theme.shadows[6],
  },
  transition: 'box-shadow 0.3s ease-in-out'
}));

const StyledButton = styled('button')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: '0.6rem 1.2rem',
  border: 'none',
  borderRadius: theme.shape.borderRadius,
  fontSize: '0.9rem',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    transform: 'translateY(-1px)',
  },
  '&:active': {
    transform: 'translateY(1px)',
  }
}));

const InfoSection = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
}));

const Settings = ({ user, userData }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [email, setEmail] = useState(user?.email || '');
  const [language, setLanguage] = useState(i18n.language);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const handlePasswordReset = () => {
    if (!email) {
      setSnackbar({
        open: true,
        message: t('please_provide_email'),
        severity: 'error'
      });
      return;
    }

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSnackbar({
          open: true,
          message: t('password_reset_email_sent'),
          severity: 'success'
        });
      })
      .catch((err) => {
        console.error('Error sending password reset email:', err);
        setSnackbar({
          open: true,
          message: t('error_sending_password_reset_email'),
          severity: 'error'
        });
      });
  };

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    setSnackbar({
      open: true,
      message: t('language_changed'),
      severity: 'success'
    });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setSnackbar({
          open: true,
          message: t('copied_to_clipboard'),
          severity: 'success'
        });
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
        setSnackbar({
          open: true,
          message: t('failed_to_copy'),
          severity: 'error'
        });
      });
  };

  return (
    <Box sx={{  }}>
      <StyledCard>
        <CardContent>
          <Stack spacing={4}>
            {/* Header */}
            <Typography variant="h5" fontWeight="bold" color="primary">
              {t('settings')}
            </Typography>

            {/* User Information Section */}
            <Box>
              <Stack direction="row" alignItems="center" spacing={1} mb={2}>
                <InfoIcon color="primary" />
                <Typography variant="h6">
                  {t('user_information')}
                </Typography>
              </Stack>

              <InfoSection>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography variant="body1">
                        <strong>{t('user_uid')}:</strong> {user.uid}
                      </Typography>
                      <IconButton
                        size="small"
                        onClick={() => copyToClipboard(user.uid)}
                        color="primary"
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Stack>
                  </Grid>
                
                </Grid>
              </InfoSection>
            </Box>

            <Divider />

            {/* Password Reset Section */}
            <Box>
              <Stack direction="row" alignItems="center" spacing={1} mb={2}>
                <LockResetIcon color="primary" />
                <Typography variant="h6">
                  {t('forgot_password')}
                </Typography>
              </Stack>

              <Stack spacing={2}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>{t('email')}</InputLabel>
                  <Select
                    value={email}
                    label={t('email')}
                    disabled
                  >
                    <MenuItem value={email}>{email}</MenuItem>
                  </Select>
                </FormControl>

                <StyledButton onClick={handlePasswordReset}>
                  <LockResetIcon />
                  {t('reset_password')}
                </StyledButton>
              </Stack>
            </Box>

            <Divider />

            {/* Language Settings Section */}
            <Box>
              <Stack direction="row" alignItems="center" spacing={1} mb={2}>
                <LanguageIcon color="primary" />
                <Typography variant="h6">
                  {t('language_settings')}
                </Typography>
              </Stack>

              <FormControl fullWidth variant="outlined">
                <InputLabel id="language-select-label">
                  {t('select_language')}
                </InputLabel>
                <Select
                  labelId="language-select-label"
                  value={language}
                  onChange={handleLanguageChange}
                  label={t('select_language')}
                >
                  <MenuItem value="en">{t('english')}</MenuItem>
                  <MenuItem value="ar">{t('arabic')}</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Stack>
        </CardContent>
      </StyledCard>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Settings;