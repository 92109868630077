import { CardContent, Chip, Grid, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { loadTransactionData } from './Data_Managements/Transactions_data';
import { StyledBox, StyledCard, StyledTableCell, StyledTableRow, StyledTextField } from './Styles/GetTheme';




const TransactionsManager = () => {
  const [transactions, setTransactions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredTransactions, setFilteredTransactions] = useState([]);

  useEffect(() => {
    // Fetch transaction data on component mount
    const fetchData = async () => {
      try {
        const stats = await loadTransactionData();
        setTransactions(stats.recentTransactions);
      } catch (error) {
        console.error("Error fetching transactions: ", error);
      }
    };
    fetchData();
  }, []);

  // Filter transactions by client name
  useEffect(() => {
    if (searchTerm) {
      setFilteredTransactions(
        transactions.filter(transaction => 
          transaction.clientName.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setFilteredTransactions(transactions);
    }
  }, [searchTerm, transactions]);

  return (
    <StyledBox>
      <Typography variant="h4" gutterBottom>
        Transactions Manager
      </Typography>
      
      {/* Search Field */}
      <StyledTextField
        label="Search by Client Name"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Transactions Table */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom fontWeight="bold">
                Recent Transactions
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Client Name</StyledTableCell>
                      <StyledTableCell>Order Date</StyledTableCell>
                      <StyledTableCell>Transaction Amount</StyledTableCell>
                      <StyledTableCell>Payment Method</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell> {/* Added Status Column */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredTransactions.length > 0 ? (
                      filteredTransactions.map((transaction, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell>{transaction.clientName}</StyledTableCell>
                          <StyledTableCell>{transaction.orderDate}</StyledTableCell>
                          <StyledTableCell>{transaction.transactionAmount} dt</StyledTableCell>
                          <StyledTableCell>{transaction.paymentMethod}</StyledTableCell>
                          <StyledTableCell>
                            <Chip label="Approved" color="success" /> {/* Friendly Approved Status */}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell colSpan={5} align="center">
                          No transactions found
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export default TransactionsManager;
