import { Button, CardContent, Checkbox, FormControlLabel, Grid, MenuItem, Paper, Select, Table, TableBody, TableContainer, Typography } from '@mui/material';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import React, { useState } from 'react';
import { loadOrderData } from './Data_Managements/Orders_data';
import { StyledBox, StyledCard, StyledTableCell, StyledTableRow, StyledTextField } from './Styles/GetTheme';

const RapportManager = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const [reportData, setReportData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [useDateRange, setUseDateRange] = useState(false);
 

  const handleGenerateReport = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const allOrdersData = await loadOrderData();
      console.log("All orders data:", allOrdersData);
      const filteredOrders = filterOrders(allOrdersData.recentOrders, startDate, endDate, orderStatus, useDateRange);
      console.log("Filtered orders:", filteredOrders);
      const report = generateReport(filteredOrders);
      setReportData(report);
    } catch (error) {
      console.error("Error generating report:", error);
      setError("Failed to generate report. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return new Date(year, month - 1, day);  // month is 0-indexed in JavaScript Date
  };

  const filterOrders = (orders, start, end, status, useDate) => {
    console.log("Filtering orders:", { start, end, status, useDate });
    
    // Filter the orders based on the given criteria
    const filteredOrders = orders.filter(order => {
      const orderDate = parseDate(order.orderDate);
      
      let isInDateRange = true;
      if (useDate && start && end) {
        const startDate = new Date(start);
        const endDate = new Date(end);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        isInDateRange = orderDate >= startDate && orderDate <= endDate;
      }
      
      const matchesStatus = !status || order.status === status;
      return isInDateRange && matchesStatus;
    });
  
    // Sort the filtered orders by date (ascending order)
    return filteredOrders.sort((a, b) => parseDate(a.orderDate) - parseDate(b.orderDate));
  };
  

  const generateReport = (orders) => {
    const totalOrders = orders.length;
    const totalPaid = orders.reduce((sum, order) => sum + order.orderAmount, 0);
    const totalResteToPay = orders.reduce((sum, order) => sum + (order.orderAmount - (order.Avance || 0)), 0);
    const totalProfit= orders.reduce((sum, order) => sum + order.orderProfit, 0);

    return {
      totalOrders,
      totalPaid,
      totalResteToPay,
      totalProfit,
      orders
    };
  };

  const handleGeneratePDF = () => {
    if (!reportData) return;

    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;

    // Modern Header
    doc.setFillColor(41, 128, 185); // Set a blue background color
    doc.rect(0, 0, pageWidth, 40, 'F');

    doc.setTextColor(255); // White text
    doc.setFont("helvetica", "bold");
    doc.setFontSize(24);
    doc.text("Amine Jameli Services", 10, 25);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(16);
    doc.text("Order Report", pageWidth - 10, 25, { align: "right" });

    // Horizontal line
    doc.setDrawColor(255);
    doc.setLineWidth(0.5);
    doc.line(10, 32, pageWidth - 10, 32);

    // Report details
    doc.setTextColor(0); // Black text
    doc.setFontSize(12);
    doc.text(`Date Generated: ${new Date().toLocaleDateString()}`, 10, 50);
    doc.text(`Number of Orders: ${reportData.totalOrders}`, 10, 58);
    if (useDateRange && startDate && endDate) {
      doc.text(`Date Range: ${startDate} to ${endDate}`, 10, 66);
    }

    // Table
    const tableData = reportData.orders.map(order => [
      '',  // Checkbox column
      order.clientName,
      (order.orderAmount - (order.Avance || 0)).toFixed(2),
      order.Site || 'N/A'
    ]);

    doc.autoTable({
      startY: 75,
      head: [['', 'Client Name', 'Reste To Pay (TND)', 'Site']],
      body: tableData,
      theme: 'grid',
      styles: { fontSize: 10, cellPadding: 5 },
      headStyles: { fillColor: [41, 128, 185], textColor: 255, fontStyle: 'bold' },
      alternateRowStyles: { fillColor: [245, 245, 245] },
      columnStyles: {
        0: { cellWidth: 10 }, // Checkbox column
        1: { cellWidth: 'auto' },
        2: { cellWidth: 40 },
        3: { cellWidth: 40 }
      },
      didDrawCell: (data) => {
        if (data.column.index === 0 && data.cell.section === 'body') {
          const dim = data.cell.height - 2;
          const x = data.cell.x + 2;
          const y = data.cell.y + 2;
          doc.rect(x, y, dim, dim, 'S');
        }
      }
    });

    // Footer
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.setTextColor(150);
      doc.text(`Page ${i} of ${pageCount}`, pageWidth / 2, pageHeight - 10, { align: 'center' });
    }

    doc.save("amine_jameli_services_report.pdf");
  };

  return (
    <StyledBox>
      <Typography variant="h4" gutterBottom>
        Report Manager
      </Typography>
      
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={useDateRange}
                onChange={(e) => setUseDateRange(e.target.checked)}
                color="primary"
              />
            }
            label="Use Date Range"
          />
        </Grid>
        {useDateRange && (
          <>
            <Grid item xs={6}>
              <StyledTextField
                label="Start Date"
                type="date"
                fullWidth
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <StyledTextField
                label="End Date"
                type="date"
                fullWidth
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Select
            value={orderStatus}
            fullWidth
            displayEmpty
            onChange={(e) => setOrderStatus(e.target.value)}
          >
            <MenuItem value="">All Statuses</MenuItem>
            <MenuItem value="in transit to Tunisia">In Transit To Tunisia</MenuItem>
            <MenuItem value="delivered">Delivered</MenuItem>
            <MenuItem value="Cancelled">Cancelled</MenuItem>
          </Select>
        </Grid>
      </Grid>

      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleGenerateReport} 
        disabled={isLoading}
      >
        {isLoading ? 'Generating...' : 'Generate Report'}
      </Button>

      {error && (
        <Typography color="error" style={{ marginTop: '10px' }}>
          {error}
        </Typography>
      )}

      {reportData && (
        <StyledCard style={{ marginTop: '20px' }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>Report Summary</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>Total Orders</StyledTableCell>
                    <StyledTableCell>{reportData.totalOrders}</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>Total Paid (TND)</StyledTableCell>
                    <StyledTableCell>{reportData.totalPaid.toFixed(2)}</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>Total Reste To Pay (TND)</StyledTableCell>
                    <StyledTableCell>{reportData.totalResteToPay.toFixed(2)}</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>Total Profit (TND)</StyledTableCell>
                    <StyledTableCell>{reportData.totalProfit.toFixed(2)}</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Button 
              variant="contained" 
              color="secondary" 
              onClick={handleGeneratePDF} 
              style={{ marginTop: '20px' }}
            >
              Generate PDF Report
            </Button>
          </CardContent>
        </StyledCard>
      )}
    </StyledBox>
  );
};

export default RapportManager;