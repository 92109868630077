import { CardContent, Chip, Grid, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { loadOrderData } from './Data_Managements/Orders_data';
import { StyledBox, StyledCard, StyledTableCell, StyledTableRow, StyledTextField } from './Styles/GetTheme';






const OrdersManager = () => {
  const [orders, setOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOrders, setFilteredOrders] = useState([]);

  useEffect(() => {
    // Fetch order data on component mount
    const fetchData = async () => {
      try {
        const stats = await loadOrderData();
        setOrders(stats.recentOrders);
      } catch (error) {
        console.error("Error fetching orders: ", error);
      }
    };
    fetchData();
  }, []);

  // Filter orders by client name
  useEffect(() => {
    if (searchTerm) {
      setFilteredOrders(
        orders.filter(order =>
          order.clientName.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setFilteredOrders(orders);
    }
  }, [searchTerm, orders]);

  return (
    <StyledBox>
      <Typography variant="h4" gutterBottom>
        Orders Manager
      </Typography>
      
      {/* Search Field */}
      <StyledTextField
        label="Search by Client Name"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Orders Table */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom fontWeight="bold">
                Recent Orders
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Client Name</StyledTableCell>
                      <StyledTableCell>Order Date</StyledTableCell>
                      <StyledTableCell>Order Amount</StyledTableCell>
                      <StyledTableCell>Payment Method</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell> {/* Added Status Column */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredOrders.length > 0 ? (
                      filteredOrders.map((order, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell>{order.clientName}</StyledTableCell>
                          <StyledTableCell>{order.orderDate}</StyledTableCell>
                          <StyledTableCell>{order.orderAmount} dt</StyledTableCell>
                          <StyledTableCell>{order.paymentMethod}</StyledTableCell>
                          <StyledTableCell>
                            <Chip label={order.status} color="success" /> 
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell colSpan={5} align="center">
                          No orders found
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export default OrdersManager;
