import React, { useState } from 'react';
import { FaBox, FaShoppingCart, FaShippingFast, FaHandshake } from 'react-icons/fa';
import './HowItWorks.css'; // Import the CSS file for styling

const HowItWorks = () => {
  const [activeStep, setActiveStep] = useState(null);
  const steps = [
    {
      id: 1,
      title: 'الخطوة 1: اختر منتجك من المواقع العالمية',
      description: 'تصفح مواقع الإنترنت واختر المنتجات التي ترغب في شرائها. أرسل لنا صورة المنتج ورابط سلة التسوق.',
      icon: <FaBox size={50} />,
    },
    {
      id: 2,
      title: 'الخطوة 2: إرسال تفاصيل الطلب',
      description: 'قم بإرسال صورة المنتج ورابط سلة التسوق إلينا لنتولى الباقي.',
      icon: <FaShoppingCart size={50} />,
    },
    {
      id: 3,
      title: 'الخطوة 3: شحن المنتج إلى أوروبا',
      description: 'نستقبل المنتج في مستودعاتنا في أوروبا ونتولى عملية الشحن إلى تونس.',
      icon: <FaShippingFast size={50} />,
    },
    {
      id: 4,
      title: 'الخطوة 4: استلام منتجك في تونس',
      description: 'نقوم بشحن المنتج إلى عنوانك في تونس، واستمتع بشرائك الجديد.',
      icon: <FaHandshake size={50} />,
    },
  ];
  

  return (
    <section className="how-it-works">
      <div className="heading_container">
      <h2>كيف تسير الأمور</h2>
<p>اتبع هذه الخطوات لتتعرف على كيفية الاستفادة من خدمتنا بسهولة.</p>
      </div>
      <div className="steps_container">
        {steps.map(step => (
          <div
            key={step.id}
            className={`step ${activeStep === step.id ? 'active' : ''}`}
            onClick={() => setActiveStep(activeStep === step.id ? null : step.id)}
          >
            <div className="icon-box">
              {step.icon}
            </div>
            <div className="detail-box">
              <h5>{step.title}</h5>
              <p>{step.description}</p>
              <span>{activeStep === step.id ? 'عرض أقل' : 'عرض المزيد'}</span>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HowItWorks;
