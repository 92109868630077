import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Typography, Button, Grid, Snackbar, CircularProgress, Box } from '@mui/material';
import { MailOutline as MailOutlineIcon, ErrorOutline as ErrorOutlineIcon, Logout as LogoutIcon } from '@mui/icons-material';
import { getAuth, sendEmailVerification, signOut } from 'firebase/auth';

const EmailNotVerified = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [cooldownTime, setCooldownTime] = useState(0);

  useEffect(() => {
    let timer;
    if (cooldownTime > 0) {
      timer = setTimeout(() => setCooldownTime(cooldownTime - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [cooldownTime]);

  const handleResend = async () => {
    if (cooldownTime > 0) return;

    setLoading(true);
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        await sendEmailVerification(user);
        setSnackbarMessage(t('emailSent'));
        setCooldownTime(60); // 1min
      } else {
        setSnackbarMessage(t('userNotFound'));
      }
    } catch (error) {
        console.error('Error sending verification email:', error);
      if (error.code === 'auth/too-many-requests') {
        setSnackbarMessage(t('tooManyRequests'));
        setCooldownTime(300); // 5min
      } else {
        setSnackbarMessage(t('errorSendingEmail'));
      }
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleLogout = async () => {
    try {
      const auth = getAuth();
      await signOut(auth);
      setSnackbarMessage(t('logoutSuccess'));
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error logging out:', error);
      setSnackbarMessage(t('logoutError'));
      setSnackbarOpen(true);
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh', backgroundColor: '#f5f5f5' }}>
      <Grid item xs={11} sm={8} md={6} lg={4}>
        <Card variant="outlined" sx={{ boxShadow: 3, borderRadius: 2 }}>
          <CardContent sx={{ textAlign: 'center', padding: 4 }}>
            <ErrorOutlineIcon color="error" sx={{ fontSize: 80, marginBottom: 2 }} />
            <Typography variant="h4" component="div" gutterBottom fontWeight="bold">
              {t('title')}
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
              {t('message')}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <MailOutlineIcon />}
                onClick={handleResend}
                disabled={loading || cooldownTime > 0}
                sx={{
                  padding: '10px 20px',
                  fontSize: '1rem',
                  textTransform: 'none',
                  borderRadius: 50,
                  width: '100%',
                  maxWidth: 300,
                }}
              >
                {cooldownTime > 0 ? `${t('waitBeforeResend')} (${cooldownTime}s)` : (loading ? t('sending') : t('button'))}
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<LogoutIcon />}
                onClick={handleLogout}
                sx={{
                  padding: '10px 20px',
                  fontSize: '1rem',
                  textTransform: 'none',
                  borderRadius: 50,
                  width: '100%',
                  maxWidth: 300,
                }}
              >
                {t('logout')}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Grid>
  );
};

export default EmailNotVerified;