import { firebaseFetchManager } from "./Firebase Manager/fetch-data"; // Update with the correct path to your Firebase manager

const processSettings = (taux, announcements) => {
  let Taux = 0.0;
  let Alerte = '';
  let Link = '';

  taux.forEach((rate) => {
    // Collect taux
    Taux= rate.taux || 0.0;
  });

  announcements.forEach((announcement) => {
    // Collect announcements
    Alerte= announcement.text || "";
    Link= announcement.url || "";
  });

  return {
    Taux,
    Alerte,
    Link, // Use the collected recent shippings array
  };
};

export const loadSettingsData = async () => {
  try {
    console.log("Starting to fetch taux data...");
    const { data: TauxData } = await firebaseFetchManager.fetchTaux();
    console.log("Starting to fetch announcements data...");
    const { data: announcementsData } =
      await firebaseFetchManager.fetchAnnouncements();

    console.log("Fetched taux data:", TauxData);
    console.log("Fetched announcements data:", announcementsData);

    // Process the shippings
    const SettingsStats = processSettings(TauxData, announcementsData);

    console.log("Processed Settings stats:", SettingsStats);

    return SettingsStats;
  } catch (error) {
    console.error("Error loading Settings data: ", error);
    throw error;
  }
};
