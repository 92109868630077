import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { HomeIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth, firestore } from '../../config/firebaseConfig';
import './AuthForm.css';

const AuthForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isSignIn, setIsSignIn] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [error, setError] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [redirectPath, setRedirectPath] = useState('/me');
  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const action = searchParams.get('action');
    if (action === 'feedbacks') {
      setRedirectPath('/feedbacks');
    }
  }, [location]);

  const handleTabClick = () => {
    setIsSignIn(!isSignIn);
    setError('');
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      if (isSignIn) {
        await signInWithEmailAndPassword(auth, email, password);
        console.log(redirectPath);
        navigate(redirectPath);
      } else {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        // Send email verification
        await sendEmailVerification(user);

        await setDoc(doc(firestore, 'users', user.uid), {
          uid: user.uid,
          shortId: Math.random().toString(36).substring(2, 8),
          fullName: fullName,
          email: email,
          role: 'user',
          sudo: false,
          profilePicture: null,
          points: 0,
          coupons: [],
          phoneNumber: null,
          address: null
        });

        // Open dialog to notify the user
        setOpenDialog(true);
      }
    } catch (error) {
      console.error("Authentication error:", error);
      setError(getErrorMessage(error.code));
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    navigate(redirectPath);
  };

  const getErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 'auth/email-already-in-use':
        return t('error_email_already_in_use');
      case 'auth/invalid-email':
        return t('error_invalid_email');
      case 'auth/weak-password':
        return t('error_weak_password');
      case 'auth/user-not-found':
        return t('error_user_not_found');
      case 'auth/wrong-password':
        return t('error_wrong_password');
      case 'auth/too-many-requests':
        return t('error_too_many_requests');
      default:
        return t('error_generic');
    }
  };

  return (
    <div className="wrapper fadeInDown">
      
      <div id="formContent">
        {/* New section for home page redirect */}
      <div className="homeRedirect">
          <button onClick={() => navigate('/')} className="homeButton">
            <HomeIcon size={18} className="homeIcon"/>
          </button>
        </div>
        {/* Header Tabs */}
        <h2
          className={isSignIn ? 'active' : 'inactive underlineHover'}
          onClick={handleTabClick}
        >
          {t(isSignIn ? 'login' : 'create_account')}
        </h2>
        <h2
          className={!isSignIn ? 'active' : 'inactive underlineHover'}
          onClick={handleTabClick}
        >
          {t(isSignIn ? 'create_account' : 'login')}
        </h2>

        {/* Icon */}
        <div className="fadeIn first">
          <img src="images/amine_jameli.png" id="icon" alt={t('welcome_image_alt')} />
        </div>

        {/* Form */}
        <form onSubmit={handleFormSubmit}>
          {!isSignIn && (
            <input
              type="text"
              id="fullName"
              className="fadeIn first"
              name="fullName"
              placeholder={t('full_name')}
              value={fullName}
              onChange={handleFullNameChange}
            />
          )}
          <input
            type="email"
            id="login"
            className="fadeIn second"
            name="login"
            placeholder={t('email')}
            value={email}
            onChange={handleEmailChange}
          />
          <input
            type="password"
            id="password"
            className="fadeIn third"
            name="password"
            placeholder={t('password')}
            value={password}
            onChange={handlePasswordChange}
          />
          <input
            type="submit"
            className="fadeIn fourth"
            value={isSignIn ? t('login_button') : t('register')}
          />
        </form>

        {/* Error Message */}
        {error && <p className="error">{error}</p>}

        {/* Dialog for email verification */}
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
        >
          <DialogTitle>{t('verification_sent_title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('verification_sent_message')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              {t('ok')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Footer */}
        <div id="formFooter">
          {isSignIn ? (
            <a className="underlineHover" href="#">
              {t('forgot_password')}
            </a>
          ) : (
            <a className="underlineHover" href="#">
              {t('already_have_account')}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthForm;
