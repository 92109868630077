import React, { useState } from "react";
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  serverTimestamp,
} from "firebase/firestore";
import { firestore } from "../../../config/firebaseConfig"; // Ensure the correct path
import "./newsletter-signup.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NewsletterSignupSection = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const isValidEmail = (email) => {
    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSignup = async () => {
    if (email) {
      if (!isValidEmail(email)) {
        toast.warning("يرجى إدخال عنوان بريد إلكتروني صالح", {
          position: "top-center",
          autoClose: 3000,
        });
        return;
      }
  
      setLoading(true);
      try {
        console.log("Checking if email exists:", email);
  
        // Check if email already exists
        const querySnapshot = await getDocs(
          query(
            collection(firestore, "newsletter"),
            where("mail", "==", email.toLowerCase())
          )
        );
  
        if (!querySnapshot.empty) {
          toast.info("هذا البريد الإلكتروني مشترك بالفعل!", {
            position: "top-center",
            autoClose: 3000,
          });
          setEmail("");
          return;
        }
  
        // If email doesn't exist, proceed with adding it
        await addDoc(collection(firestore, "newsletter"), {
          mail: email.toLowerCase(), // Store emails in lowercase for consistency
          createdAt: serverTimestamp(), // Optional: add timestamp
        });
  
        console.log("Email added successfully");
        toast.success("شكراً لاشتراكك!", {
          position: "top-center",
          autoClose: 3000,
        });
        setEmail("");
      } catch (error) {
        console.error("Error:", error.message);
        toast.error("حدث خطأ. يرجى المحاولة مرة أخرى", {
          position: "top-center",
          autoClose: 3000,
        });
      } finally {
        setLoading(false);
      }
    } else {
      toast.warning("يرجى إدخال عنوان بريد إلكتروني صالح", {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };
  return (
    <section className="newsletter_signup_section">
      <div className="heading_container">
        <h2> اشترك معنا لتلقي اخر مواعدنا و عروضنا المميزة</h2>
      </div>
      <div className="signup_container">
        <input
          type="email"
          placeholder="أدخل بريدك الإلكتروني"
          className="email_input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button
          className="signup_button"
          onClick={handleSignup}
          disabled={loading}
        >
          {loading ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            "اشترك"
          )}
        </button>
      </div>
      <ToastContainer />
    </section>
  );
};

export default NewsletterSignupSection;
