import { CardContent, Chip, Grid, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { loadShippingData } from './Data_Managements/Shippings_data'; // Update the import to match your data management structure
import { StyledBox, StyledCard, StyledTableCell, StyledTableRow, StyledTextField } from './Styles/GetTheme';



const ShippingsManager = () => {
  const [shippings, setShippings] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredShippings, setFilteredShippings] = useState([]);

  useEffect(() => {
    // Fetch shipping data on component mount
    const fetchData = async () => {
      try {
        const stats = await loadShippingData();
        setShippings(stats.recentShippings);
      } catch (error) {
        console.error("Error fetching shippings: ", error);
      }
    };
    fetchData();
  }, []);

  // Filter shippings by shipping ID or other criteria as needed
  useEffect(() => {
    if (searchTerm) {
      setFilteredShippings(
        shippings.filter(shipping => 
          shipping.shippingCompany.toString().includes(searchTerm) // Adjust this to filter by other criteria
        )
      );
    } else {
      setFilteredShippings(shippings);
    }
  }, [searchTerm, shippings]);

  return (
    <StyledBox>
      <Typography variant="h4" gutterBottom>
        Shippings Manager
      </Typography>

      {/* Search Field */}
      <StyledTextField
        label="Search by  Company"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />


      {/* Shippings Table */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom fontWeight="bold">
                Recent Shippings
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Transpoter</StyledTableCell>
                      <StyledTableCell>Shipping Date</StyledTableCell>
                      <StyledTableCell>Shipping Weight</StyledTableCell>
                      <StyledTableCell>Shipping Fees</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredShippings.length > 0 ? (
                      filteredShippings.map((shipping, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell>
                            {shipping.shippingCompany}
                          </StyledTableCell>
                          <StyledTableCell>
                            {shipping.shippingDate}
                          </StyledTableCell>
                          <StyledTableCell>
                            {shipping.shippingWeight} kg
                          </StyledTableCell>
                          <StyledTableCell>
                            {shipping.shippingFees} TND
                          </StyledTableCell>
                          <StyledTableCell>
                            <Chip
                              label={shipping.status || "Unknown"}
                              color={
                                shipping.status === "In Transit"
                                  ? "info"
                                  : shipping.status === "Delivered"
                                  ? "success"
                                  : "error" // Default color for other statuses
                              }
                            />
                            {/* Friendly Status */}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell colSpan={5} align="center">
                          No shippings found
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export default ShippingsManager;
