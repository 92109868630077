import React from 'react';
import { Box, Typography } from '@mui/material';
import { Player } from '@lottiefiles/react-lottie-player';
import { useTranslation } from 'react-i18next';
import loadingAnimation from '../../assets/AJS_LOADING.json';

const Loading2 = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: '#f4f4f4',
        textAlign: 'center',
      }}
    >
      <Player
        autoplay
        loop
        src={loadingAnimation}
        style={{ height: '400px', width: '400px' }}
      />
      {/* <Typography variant="h6" sx={{ color: '#666', mt: 4  }}>
        {t('loading')}
      </Typography> */}
    </Box>
  );
};

export default Loading2;
