import { loadFeedbacksData } from './Feedbacks_data';
import { loadOrderData } from './Orders_data';
import { loadShippingData } from './Shippings_data';
import { loadUserData } from './Users_data';
import { loadNewsletterData } from './Newsletter_data';

export const loadBackupData = async () => {
  try {
    console.log("Starting to load backup data...");

    const [feedbacksData, ordersData, shippingData, userData , newslatterData] = await Promise.all([
      loadFeedbacksData(),
      loadOrderData(),
      loadShippingData(),
      loadUserData(),
      loadNewsletterData()
    ]);

    const backupData = {
      feedbacks: feedbacksData,
      orders: ordersData,
      shippings: shippingData,
      users: userData.users,
      newsletter : newslatterData
       // Note: we're only taking the users array, not the pagination info
    };

    console.log("Backup data loaded successfully");
    return backupData;
  } catch (error) {
    console.error("Error loading backup data:", error);
    throw error;
  }
};

export const generateBackupFiles = async (collectionsToBackup) => {
    try {
      // Load all backup data
      const backupData = await loadBackupData();
  
      // Filter only the collections that are selected by the user
      collectionsToBackup.forEach((collection) => {
        // Ensure we're only generating files for the selected collections
        if (backupData[collection]) {
          const dataString = JSON.stringify(backupData[collection], null, 2);
          const blob = new Blob([dataString], { type: 'application/json' });
          const url = URL.createObjectURL(blob);
  
          const link = document.createElement('a');
          link.href = url;
          link.download = `${collection}_backup_${new Date().toISOString()}.json`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.warn(`Collection ${collection} not found in backup data.`);
        }
      });
  
      console.log("Selected backup files generated and download initiated");
    } catch (error) {
      console.error("Error generating backup files:", error);
      throw error;
    }
  };
  
  