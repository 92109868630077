import { firebaseFetchManager } from './Firebase Manager/fetch-data';

const processTransactions = (orders) => {
  let totalTransactions = orders.length;
  let totalTransactionAmount = 0;
  let monthlyTransactions = {};
  let paymentMethods = {};

  orders.forEach(order => {
    const transactionAmount = order.clientFirstPaycheck || 0;
    const orderDate = new Date(order.orderDate.seconds * 1000);
    const monthYear = orderDate.toLocaleString('default', { month: 'short', year: 'numeric' });

    // Summing the total transaction amount
    totalTransactionAmount += transactionAmount;

    // Monthly transaction statistics
    monthlyTransactions[monthYear] = (monthlyTransactions[monthYear] || 0) + transactionAmount;

    // Payment method distribution
    if (order.paymentMethod) {
      paymentMethods[order.paymentMethod] = (paymentMethods[order.paymentMethod] || 0) + transactionAmount;
    }
  });

  return {
    totalTransactions,
    totalTransactionAmount: totalTransactionAmount.toFixed(2),
    averageTransactionAmount: (totalTransactionAmount / totalTransactions).toFixed(2),
    monthlyTransactions: Object.entries(monthlyTransactions).map(([month, amount]) => ({
      month,
      amount: parseFloat(amount.toFixed(2))
    })),
    topPaymentMethods: Object.entries(paymentMethods).sort(([, a], [, b]) => b - a).slice(0, 5).map(([method, amount]) => ({
      method,
      amount: parseFloat(amount.toFixed(2))
    })),
    recentTransactions: orders.map(order => ({
      clientName : order.customerName || 'Unknown' ,
      orderDate: new Date(order.orderDate.seconds * 1000).toLocaleDateString(),
      transactionAmount: order.clientFirstPaycheck || 0,
      paymentMethod: order.paymentMethod || 'Unknown'
    }))
  };
};

export const loadTransactionData = async () => {
  try {
    console.log("Starting to fetch transactions data...");
    const ordersData = await firebaseFetchManager.fetchOrders();

    console.log("Fetched transaction data:", ordersData);

    // Process the transactions
    const transactionsStats = processTransactions(ordersData.data);

    console.log("Processed transaction stats:", transactionsStats);

    return transactionsStats;
  } catch (error) {
    console.error("Error loading transactions data: ", error);
    throw error;
  }
};
