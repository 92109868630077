import { getFirestore, collection, doc, onSnapshot, query, where, getDoc , getDocs} from 'firebase/firestore';

const db = getFirestore(); // Initialize Firestore once

// Cache storage
const cache = {
  userStats: {},
  notifications: {},
};

// Cache expiration time in milliseconds (e.g., 5 minutes)
const CACHE_EXPIRATION_TIME = 5 * 60 * 1000;

// Helper function to check if cache is valid
const isCacheValid = (cacheTimestamp) => {
  return Date.now() - cacheTimestamp < CACHE_EXPIRATION_TIME;
};

/**
 * Fetches user data from Firestore.
 * @param {string} userId - The user ID.
 * @returns {object|null} - The user data or null if no user found.
 */
export const fetchUserData = async (userId) => {
  if (!userId) return null;

  try {
    const userDocRef = doc(db, 'users', userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      return userDocSnap.data(); // Return user data
    } else {
      console.log('No such user!');
      return null;
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

/**
 * Fetches user statistics with caching.
 * @param {string} currentUserUid - The user ID.
 * @returns {object|null} - The user statistics or null if no user ID.
 */
export const fetchUserStatistics = async (currentUserUid) => {
  if (!currentUserUid) return null;

  // Check if stats are in cache and still valid
  if (cache.userStats[currentUserUid] && isCacheValid(cache.userStats[currentUserUid].timestamp)) {
    return cache.userStats[currentUserUid].data;
  }

  const ordersRef = collection(db, 'orders');
  const q = query(ordersRef, where('customerUid', '==', currentUserUid));

  try {
    const querySnapshot = await getDocs(q);

    const stats = querySnapshot.docs.reduce((acc, doc) => {
      const data = doc.data();
      acc.totalOrders += 1;
      acc.totalPriceInTnd += data.priceInTnd || 0;
      acc.totalPayCheck  += data.clientFirstPaycheck || 0;
      acc.totalResteToPay += ( data.priceInTnd - data.clientFirstPaycheck ) || 0;
      if (data.status === 'delivered') acc.ordersDelivered += 1;
      if (!acc.lastOrderDate || data.orderDate > acc.lastOrderDate) {
        acc.lastOrderDate = data.orderDate;
      }
      return acc;
    }, {
      totalOrders: 0,
      totalPriceInTnd: 0,
      totalResteToPay :  0, 
      ordersDelivered: 0,
      lastOrderDate: null,
    });

    // Cache the result with a timestamp
    cache.userStats[currentUserUid] = { data: stats, timestamp: Date.now() };
    return stats;
  } catch (error) {
    console.error('Error fetching statistics:', error);
    throw error;
  }
};

/**
 * Listens for notifications for a specific user in real-time using Firestore.
 * @param {string} userId - The user ID.
 * @param {function} onUpdate - Callback function to update notifications in the UI.
 * @returns {function} Unsubscribe function to stop listening for updates.
 */
export const listenForNotifications = (userId, onUpdate) => {
  if (!userId) return () => {};

  // If notifications are in cache and valid, use cached data initially
  if (cache.notifications[userId] && isCacheValid(cache.notifications[userId].timestamp)) {
    onUpdate(cache.notifications[userId].data);
  }

  // Reference to the user's notifications collection
  const notificationsRef = collection(db, 'notifications'); // Accessing the notifications collection

  // Set up a real-time listener for notifications of a specific user
  const unsubscribe = onSnapshot(notificationsRef, (snapshot) => {
    const notifications = snapshot.docs
      .filter(doc => doc.data().userId === userId) // Filter by userId
      .map(doc => ({
        id: doc.id, // Use the notification ID
        Content: doc.data().Content,
        Notifications_Date: doc.data().Notifications_Date,
        Priority: doc.data().Priority,
      }));

    // Update cache and trigger the callback with new data
    cache.notifications[userId] = { data: notifications, timestamp: Date.now() };
    onUpdate(notifications);
  });

  return unsubscribe;
};

/**
 * Clears cached data, useful on logout or when cache needs resetting.
 */
export const clearCache = () => {
  cache.userStats = {};
  cache.notifications = {};
};
