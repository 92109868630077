import {
  Alert,
  Box,
  Button,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { loadUserData } from './Data_Managements/Users_data';

import { StyledBox, StyledCard, StyledTableCell, StyledTableRow, StyledTextField } from './Styles/GetTheme';

const UsersManager = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [rowsToShow, setRowsToShow] = useState(20); // State to manage number of rows displayed

  const fetchUsers = useCallback(async (lastDocSnapshot = null) => {
    setLoading(true);
    try {
      const { users: newUsers, lastDoc: newLastDoc, hasMore: newHasMore } = await loadUserData(lastDocSnapshot);
      setUsers(prevUsers => lastDocSnapshot ? [...prevUsers, ...newUsers] : newUsers);
      setLastDoc(newLastDoc);
      setHasMore(newHasMore);
    } catch (error) {
      console.error("Error fetching users: ", error);
      setSnackbar({ open: true, message: 'Failed to load users', severity: 'error' });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    if (searchTerm) {
      setFilteredUsers(
        users.filter(user =>
          user.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setFilteredUsers(users);
    }
  }, [searchTerm, users]);

  const handleUserClick = (user) => {
    setSelectedUser(user);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedUser(null);
  };

  // const handleToggleVerification = async (userId, currentStatus) => {
  //   try {
  //     const success = await toggleEmailVerification(userId, currentStatus);
  //     if (success) {
  //       setUsers(prevUsers => prevUsers.map(user => 
  //         user.id === userId ? { ...user, emailVerified: !currentStatus } : user
  //       ));
  //       setSnackbar({ open: true, message: 'Email verification status updated successfully', severity: 'success' });
  //     }
  //   } catch (error) {
  //     console.error("Error updating email verification status:", error);
  //     setSnackbar({ open: true, message: 'Failed to update email verification status', severity: 'error' });
  //   }
  // };

  const handleLoadMore = () => {
    if (hasMore && !loading) {
      fetchUsers(lastDoc);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleShowMoreRows = () => {
    setRowsToShow(prevRows => prevRows + 20); // Increment rows displayed by 20
  };

  return (
    <StyledBox>
      <Typography variant="h4" gutterBottom>
        Users Management
      </Typography>
      
      <StyledTextField
        label="Search by Client Name Or Email "
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom fontWeight="bold">
                Users List
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>User Name</StyledTableCell>
                      <StyledTableCell>Email</StyledTableCell>
                      <StyledTableCell>Role</StyledTableCell>
                      <StyledTableCell>Email Verified</StyledTableCell>
                      <StyledTableCell>Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredUsers.length > 0 ? (
                      filteredUsers.slice(0, rowsToShow).map((user, index) => (
                        <StyledTableRow key={user.id} onClick={() => handleUserClick(user)}>
                          <StyledTableCell>{user.fullName}</StyledTableCell>
                          <StyledTableCell>{user.email}</StyledTableCell>
                          <StyledTableCell>{user.role}</StyledTableCell>
                          {/* <StyledTableCell>
                            <Chip 
                              label={user.emailVerified ? "Verified" : "Not Verified"} 
                              color={user.emailVerified ? "success" : "warning"} 
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <Button
                              variant="contained"
                              color={user.emailVerified ? "warning" : "success"}
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent dialog from opening
                                handleToggleVerification(user.id, user.emailVerified);
                              }}
                            >
                              {user.emailVerified ? "Unverify" : "Verify"}
                            </Button>
                          </StyledTableCell> */}
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell colSpan={4} align="center">
                          No users found
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {hasMore && (
                <Box display="flex" justifyContent="center" mt={2}>
                  <Button onClick={handleLoadMore} disabled={loading} variant="contained">
                    {loading ? <CircularProgress size={24} /> : 'Load More'}
                  </Button>
                </Box>
              )}
              <Box display="flex" justifyContent="center" mt={2}>
                <Button onClick={handleShowMoreRows} variant="outlined">
                  Show More Rows
                </Button>
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>

      {/* User Detail Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>User Details</DialogTitle>
        <DialogContent>
          {selectedUser && (
            <Box>
              <Typography variant="h6">Full Name: {selectedUser.fullName}</Typography>
              <Typography variant="body1">Email: {selectedUser.email}</Typography>
              <Typography variant="body1">Phone Number: {selectedUser.phoneNumber}</Typography>
              <Typography variant="body1">Points: {selectedUser.points}</Typography>
              <Typography variant="body1">Role: {selectedUser.role}</Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </StyledBox>
  );
};

export default UsersManager;
