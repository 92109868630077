import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../assets/error_happen.json'; 
import { useTranslation } from 'react-i18next';

const FriendlyErrorDialog = ({ errorMessage, onRetry, onDismiss }) => {
  const { t } = useTranslation();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="dialog-overlay">
      <div className="dialog-container">
        <div className="dialog-content">
          <Lottie options={defaultOptions} height={200} width={200} />
          <div style={{ marginTop: '20px', textAlign: 'center', fontSize: '18px', color: 'red', fontWeight: 'bold' }}>
            {errorMessage}
          </div>
          <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-evenly' }}>
            <button
              onClick={() => {
                onRetry();
              }}
              style={{
                padding: '12px 24px',
                backgroundColor: 'orange',
                color: 'white',
                border: 'none',
                borderRadius: '12px',
                cursor: 'pointer'
              }}
            >
              {t('retry')}
            </button>
            <button
              onClick={onDismiss || (() => {})}
              style={{
                padding: '12px 24px',
                backgroundColor: 'grey',
                color: 'white',
                border: 'none',
                borderRadius: '12px',
                cursor: 'pointer'
              }}
            >
              {t('dismiss')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FriendlyErrorDialog;
