import {  firebaseFetchManager } from './Firebase Manager/fetch-data';



const processOrders = (orders) => {
  let totalOrders = orders.length;
  let totalRevenue = 0;
  let totalPaidInEur = 0;
  let totalPaidInTnd = 0;
  let totalUnpaidAmount = 0;
  let monthlyRevenue = {};
  let orderStatusDistribution = { Completed: 0, 'In Progress': 0, Cancelled: 0 };
  let siteSales = {};
  let paymentMethods = {};

  orders.forEach(order => {
    const orderDate = new Date(order.orderDate.seconds * 1000);
    const monthYear = orderDate.toLocaleString('default', { month: 'short', year: 'numeric' });

    totalRevenue += order.profitInTnd || 0;
    totalPaidInTnd += order.priceInTnd || 0;
    totalPaidInEur += order.pricePaidInEur || 0;
    totalUnpaidAmount += (order.priceInTnd || 0) - (order.clientFirstPaycheck || 0);

    monthlyRevenue[monthYear] = (monthlyRevenue[monthYear] || 0) + (order.profitInTnd || 0);
    if (order.status) orderStatusDistribution[order.status] = (orderStatusDistribution[order.status] || 0) + 1;
    if (order.site) siteSales[order.site] = (siteSales[order.site] || 0) + 1;
    if (order.paymentMethod) paymentMethods[order.paymentMethod] = (paymentMethods[order.paymentMethod] || 0) + (order.clientFirstPaycheck || 0);
  });

  return {
    totalOrders,
    totalPaidInEur: totalPaidInEur.toFixed(2),
    totalPaidInTnd: totalPaidInTnd.toFixed(2),
    totalRevenue: totalRevenue.toFixed(2),
    totalUnpaidAmount: totalUnpaidAmount.toFixed(2),
    averageOrderValue: (totalRevenue / totalOrders).toFixed(2),
    monthlyRevenue: Object.entries(monthlyRevenue).map(([month, revenue]) => ({ month, revenue: parseFloat(revenue.toFixed(2)) })),
    orderStatusDistribution: Object.entries(orderStatusDistribution).map(([name, value]) => ({ name, value })),
    topSellingSites: Object.entries(siteSales).sort(([, a], [, b]) => b - a).slice(0, 5).map(([name, sales]) => ({ name, sales })),
    topPaymentMethods: Object.entries(paymentMethods).sort(([, a], [, b]) => b - a).slice(0, 5).map(([name, amount]) => ({ name, amount: parseFloat(amount.toFixed(2)) })),
    recentOrders: orders.slice(0, 5)
  };
};

const processShippings = (shippings) => {
  let totalShippings = shippings.length;
  let totalShippingFees = shippings.reduce((sum, shipping) => sum + (shipping.shippingFees || 0), 0);
  let totalShippingWeight = shippings.reduce((sum, shipping) => sum + (shipping.shippingWeight || 0), 0);

  return {
    totalShippings,
    totalShippingFees: totalShippingFees.toFixed(2),
    totalShippingWeight: totalShippingWeight.toFixed(2)
  };
};

export const loadStats = async () => {
    try {
      console.log("Starting to fetch data...");
      const [usersData, ordersData, shippingsData] = await Promise.all([
        firebaseFetchManager.fetchUsers(),
        firebaseFetchManager.fetchOrders(),
        firebaseFetchManager.fetchShippings()
      ]);
  
      console.log("Fetched data:", { usersData, ordersData, shippingsData });
  
      const totalUsers = usersData.data.length;
      const ordersStats = processOrders(ordersData.data);
      const shippingsStats = processShippings(shippingsData.data);
  
      const stats = {
        totalUsers,
        ...ordersStats,
        ...shippingsStats
      };
  
      console.log("Processed stats:", stats);
  
      return stats;
    } catch (error) {
      console.error("Error loading stats: ", error);
      throw error;
    }
  };