import { firebaseFetchManager } from './Firebase Manager/fetch-data'; // Update with the correct path to your Firebase manager

const processNewsletter = (newsletter) => {
  let totalmails = newsletter.length;
  let NewsLetter_Subs = []; // Initialize an array to hold recent shippings

  newsletter.forEach(newsletter => {
    // Collect recent shippings
    NewsLetter_Subs.push({
      email : newsletter.mail || 'notfound',
    });
  });

  return {
    totalmails,
    NewsLetter_Subs // Use the collected recent shippings array
  };
};

export const loadNewsletterData = async () => {
  try {
    console.log("Starting to fetch newsletter data...");
    const { data: newsletterData } = await firebaseFetchManager.fetchNewsletter();

    console.log("Fetched newsletter data:", newsletterData);

    // Process the shippings
    const newsletterStats = processNewsletter(newsletterData);

    console.log("Processed newsletter stats:", newsletterStats);

    return newsletterStats;
  } catch (error) {
    console.error("Error loading newsletter data: ", error);
    throw error;
  }
};
