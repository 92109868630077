import { firebaseFetchManager } from './Firebase Manager/fetch-data'; // Update with the correct path to your Firebase manager

const processFeedbacks = (feedbacks) => {
  let totalfeedbacks = feedbacks.length;
  let totalRating =0 ;
  let rating = 0;
 
  let Feedbacks = []; // Initialize an array to hold recent shippings

  feedbacks.forEach(feedbacks => {
    rating = feedbacks.rating || 0 ;
    totalRating += rating ;
     

    // Collect recent shippings
    Feedbacks.push({
        message : feedbacks.message || '',
        rating : feedbacks.rating || 0 ,
        date : new Date(feedbacks.timestamp.seconds * 1000).toLocaleDateString(),
        user_id : feedbacks.userId || ''
    });
  });

  return {
    totalfeedbacks,
    avrege_rating : totalRating / totalfeedbacks,
    Feedbacks,
     // Use the collected recent shippings array
  };
};

export const loadFeedbacksData = async () => {
  try {
    console.log("Starting to fetch feedbacks data...");
    const { data: FeedbacksData } = await firebaseFetchManager.fetchFeedbacks();

    console.log("Fetched feedbacks data:", FeedbacksData);

    // Process the shippings
    const feedbacksStats = processFeedbacks(FeedbacksData);

    console.log("Processed feedbacks stats:", feedbacksStats);

    return feedbacksStats;
  } catch (error) {
    console.error("Error loading feedbacks data: ", error);
    throw error;
  }
};
