import { styled, createTheme } from '@mui/material/styles';
import { AppBar, Tab, Tabs, TextField, Button, Paper } from '@mui/material';

const createAppTheme = (mode) => createTheme({
  palette: {
    mode,
    primary: {
      main: '#FFA500',
    },
    secondary: {
      main: '#FF8C00',
    },
    background: {
      default: mode === 'light' ? '#f5f5f5' : '#303030',
      paper: mode === 'light' ? '#ffffff' : '#424242',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h6: {
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});

export const getTheme = (mode) => createAppTheme(mode);

export const AppBarStyled = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
}));

export const TabsStyled = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.main,
  },
  '& .MuiTabs-flexContainer': {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      gap: theme.spacing(3), // Add spacing between tabs
    }
  },
  // Center the scrollable tabs container
  '& .MuiTabs-scroller': {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      '& .MuiTabs-flexContainer': {
        gap: theme.spacing(3), // Consistent spacing in scrollable mode
      }
    }
  },
  // Add padding to the entire tabs container
  padding: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1, 2),
  }
}));

export const TabStyled = styled(Tab)(({ theme }) => ({
  '&.Mui-selected': {
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: '72px',
    padding: theme.spacing(1, 2), // Add padding around each tab
    // Center icon and label vertically
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .MuiTab-iconWrapper': {
      marginBottom: theme.spacing(0.5), // Space between icon and label
    },
    // Add subtle hover effect
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      borderRadius: theme.shape.borderRadius,
    }
  }
}));

// New common styled components
export const ContentWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(3),
  borderRadius: '16px',
  boxShadow: theme.palette.mode === 'dark' 
    ? '0 8px 32px rgba(0, 0, 0, 0.3)' 
    : '0 8px 32px rgba(0, 0, 0, 0.08)',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    backgroundColor: theme.palette.mode === 'dark' 
      ? theme.palette.background.default
      : theme.palette.background.paper,
    '& fieldset': {
      borderColor: theme.palette.mode === 'dark' 
        ? 'rgba(255, 255, 255, 0.23)' 
        : 'rgba(0, 0, 0, 0.23)',
      transition: theme.transitions.create(['border-color']),
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '& input': {
      color: theme.palette.mode === 'dark' 
        ? '#303030'
        : theme.palette.text.primary,
      '&::placeholder': {
        color: theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, 0.7)'
          : theme.palette.text.secondary,
        opacity: 0.7,
      },
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.mode === 'dark' 
        ? 'rgba(255, 255, 255, 0.05)' 
        : 'rgba(0, 0, 0, 0.05)',
      '& input': {
        color: theme.palette.mode === 'dark' 
          ? 'rgba(255, 255, 255, 0.5)' 
          : 'rgba(0, 0, 0, 0.38)',
      },
    },
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.7)'
      : theme.palette.text.secondary,
    '&.Mui-focused': {
      color: theme.palette.primary.main,
    },
  },
  '& .MuiFormHelperText-root': {
    color: theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.7)'
      : theme.palette.text.secondary,
  },
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.mode === 'dark' 
    ? theme.palette.background.default 
    : theme.palette.background.paper,
  borderRadius: '12px',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 20px rgba(0, 0, 0, 0.2)'
    : '0 4px 20px rgba(0, 0, 0, 0.06)',
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1, 4),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  borderRadius: '8px',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 12px rgba(255, 165, 0, 0.3)'
    : '0 4px 12px rgba(255, 165, 0, 0.2)',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: theme.palette.mode === 'dark'
      ? '0 6px 16px rgba(255, 165, 0, 0.4)'
      : '0 6px 16px rgba(255, 165, 0, 0.3)',
  },
}));