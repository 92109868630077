import AddIcon from '@mui/icons-material/Add';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import {
  Alert,
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  Typography,
} from '@mui/material';
import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { firestore } from '../../../config/firebaseConfig';
import {
  ContentWrapper,
  StyledButton,
  StyledPaper,
  StyledTextField,
} from '../Styles/User_Dashboard_Styles';

const OrderCard = ({ order }) => {
  const { t } = useTranslation();
  const getStatusColor = (status) => {
    switch(status.toLowerCase()) {
      case 'delivered': return '#4caf50';
      case 'pending': return '#ff9800';
      case 'cancelled': return '#f44336';
      default: return '#9e9e9e';
    }
  };

  return (
    <StyledPaper
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        transition: 'transform 0.3s',
        '&:hover': {
          transform: 'translateY(-5px)',
        },
      }}
    >
      <Box sx={{ p: 2, backgroundColor: '#FFF3E0', color: '#E65100' }}>
        <Typography variant="h6" fontWeight="bold">
           {t('order_from')} {order.site}
        </Typography>
        <Typography variant="body2">
         {t('order_date')} {new Date(order.orderDate.toDate()).toLocaleDateString()}
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 1, p: 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Chip 
            label={t(order.status)} 
            size="small"
            sx={{
              backgroundColor: getStatusColor(order.status),
              color: 'white',
            }}
          />
          <Typography variant="h6" fontWeight="bold" color="error">
            {order.priceInTnd} DT
          </Typography>
        </Box>
        <Typography variant="body2" color="text.secondary" mb={1}>
          {t('First_Pay_Check')}: {order.clientFirstPaycheck} Dt
        </Typography>
        <Typography variant="body2" color="text.secondary" mb={1}>
          {t('Rest')}: {order.priceInTnd - order.clientFirstPaycheck} Dt
        </Typography>
        <Typography variant="body2" color="text.secondary" mb={1}>
          {t('Payment')}: {order.paymentMethod}
        </Typography>
      </Box>
      <Box sx={{ p: 2, backgroundColor: '#FFF3E0', color: '#E65100' , textAlign: 'right' }}>
      <Typography variant="body2">
          {t('Client_Note')}: {order.customerName}
        </Typography>
      </Box>
    </StyledPaper>
  );
};

const AddOrderDialog = ({ open, onClose, onSubmit }) => {
  const [orderCode, setOrderCode] = useState('');
  const { t } = useTranslation();

  const handleSubmit = () => {
    onSubmit(orderCode);
    setOrderCode('');
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t('add_order_to_account')}</DialogTitle>
      <DialogContent>
        <StyledTextField
          autoFocus
          margin="dense"
          label={t('order_code')}
          fullWidth
          value={orderCode}
          onChange={(e) => setOrderCode(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <StyledButton 
          variant="outlined" 
          onClick={onClose}
          color="inherit"
        >
          {t('cancel')}
        </StyledButton>
        <StyledButton 
          onClick={handleSubmit}
          disabled={!orderCode.trim()}
        >
          {t('add_order')}
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

const UserOrders = ({ customerUid }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openAddOrder, setOpenAddOrder] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const { t } = useTranslation();

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const ordersCollection = collection(firestore, 'orders');
        const q = query(ordersCollection, where('customerUid', '==', customerUid));
        const querySnapshot = await getDocs(q);
        const ordersList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setOrders(ordersList);
      } catch (err) {
        setError(t('fetch_orders_error'));
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [customerUid, t]);

  const handleAddOrder = async (orderCode) => {
    try {
      const ordersCollection = collection(firestore, 'orders');
      const q = query(ordersCollection, where('orderCode', '==', orderCode.trim()));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setSnackbar({ open: true, message: t('order_not_found'), severity: 'error' });
        return;
      }

      const orderDoc = querySnapshot.docs[0];
      const orderData = orderDoc.data();

      if (orderData.customerUid && orderData.customerUid !== "N/A") {
        setSnackbar({ open: true, message: t('order_already_associated'), severity: 'error' });
        return;
      }

      await updateDoc(doc(firestore, 'orders', orderDoc.id), { customerUid });

      setSnackbar({ open: true, message: t('order_added_success'), severity: 'success' });
      setOrders(prev => [...prev, { ...orderData, customerUid, id: orderDoc.id }]);
      setOpenAddOrder(false);
    } catch (err) {
      setSnackbar({ open: true, message: t('order_add_fail'), severity: 'error' });
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <ContentWrapper>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Typography variant="h4" fontWeight="bold" color="primary">
          {t('your_orders')}
        </Typography>
        <StyledButton
          startIcon={<AddIcon />}
          onClick={() => setOpenAddOrder(true)}
        >
          {t('add_order')}
        </StyledButton>
      </Box>

      <Grid container spacing={3}>
        {orders.map(order => (
          <Grid item xs={12} sm={6} md={4} key={order.id}>
            <OrderCard order={order} />
          </Grid>
        ))}
      </Grid>

      <AddOrderDialog
        open={openAddOrder}
        onClose={() => setOpenAddOrder(false)}
        onSubmit={handleAddOrder}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity} 
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </ContentWrapper>
  );
};

export default UserOrders;