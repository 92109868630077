import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';
import styles from './Feedback_encourage.module.css';
import confettiAnimation from '../../../assets/gift_animation.json';

const FeedbackEncourage = ({ amount, onActionClick }) => {
  const [showMessage, setShowMessage] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleActionClick = () => {
    if (onActionClick) {
      onActionClick();
    }
    setShowMessage(false);
  };

  if (!showMessage) return null;

  return (
    <div className={styles.encourageContainer}>
      <div className={styles.encourageContent}>
        <div className={styles.lottieContainer}>
          <Lottie
            animationData={confettiAnimation}
            loop={true}
            autoplay={true}
          />
        </div>
        <p className={styles.encourageText}>
          {t('feedback_gift_message')} <span className={styles.amount}>{amount} دينار</span>!
        </p>
        <button className={styles.actionButton} onClick={handleActionClick}>
          {t('share_and_win')}
        </button>
      </div>
    </div>
  );
};

export default FeedbackEncourage;