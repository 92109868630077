import { firebaseFetchManager } from './Firebase Manager/fetch-data';

// Function to process orders
const processOrders = (orders) => {
  let totalOrders = orders.length;
  let totalOrderAmount = 0;
  let orderStatusCount = {};
  let paymentMethods = {};

  orders.forEach(order => {
    const orderAmount = order.transactionAmount || 0; // Use the appropriate field for the amount
    const orderStatus = order.status || 'Unknown';

    // Summing the total order amount
    totalOrderAmount += orderAmount;

    // Count orders by status
    orderStatusCount[orderStatus] = (orderStatusCount[orderStatus] || 0) + 1;

    // Payment method distribution
    if (order.paymentMethod) {
      paymentMethods[order.paymentMethod] = (paymentMethods[order.paymentMethod] || 0) + orderAmount;
    }
  });

  return {
    totalOrders,
    totalOrderAmount: totalOrderAmount.toFixed(2),
    averageOrderAmount: (totalOrderAmount / totalOrders).toFixed(2),
    orderStatusCount: Object.entries(orderStatusCount).map(([status, count]) => ({
      status,
      count
    })),
    topPaymentMethods: Object.entries(paymentMethods).sort(([, a], [, b]) => b - a).slice(0, 5).map(([method, amount]) => ({
      method,
      amount: parseFloat(amount.toFixed(2))
    })),
    recentOrders: orders.map(order => ({
      clientName: order.customerName || 'Unknown',
      orderDate: new Date(order.orderDate.seconds * 1000).toLocaleDateString(),
      orderAmount: order.priceInTnd || 0,
      orderProfit: order.profitInTnd || 0,
      Avance : order.clientFirstPaycheck || 0,
      paymentMethod: order.paymentMethod || 'Unknown',
      Site : order.site || 'ajs' ,
      status: order.status || 'Unknown'
    }))
  };
};

// Function to load orders data
export const loadOrderData = async () => {
  try {
    console.log("Starting to fetch orders data...");
    const ordersData = await firebaseFetchManager.fetchOrders();

    console.log("Fetched orders data:", ordersData);

    // Process the orders
    const ordersStats = processOrders(ordersData.data);

    console.log("Processed orders stats:", ordersStats);

    return ordersStats;
  } catch (error) {
    console.error("Error loading orders data: ", error);
    throw error;
  }
};
