import React from 'react';
import useFeedbacksWithUsers from '../../../hooks/useFeedbacks';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; 
import 'swiper/css/pagination'; // Styles for pagination
// Import Swiper modules from their specific paths
import { Autoplay, Pagination } from 'swiper/modules';

import './Feedbacks.css';

const FeedbackSection = () => {
  const { feedbacks, loading } = useFeedbacksWithUsers();
  const { t } = useTranslation();

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <p>{t('view_feedbacks')}</p>
      </div>
    );
  }

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span
          key={i}
          className={`fa fa-star ${i <= rating ? 'checked' : ''}`}
        ></span>
      );
    }
    return stars;
  };

  return (
    <section className="client_section" id="feedbacks">
      <div className="container">
        <div className="heading_container heading_center">
          <h2>{t('client_feedbacks')}</h2>
        </div>
        <Swiper
          modules={[Autoplay, Pagination]} // Add modules here
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{ clickable: true }}
          loop={true}
          className="mySwiper"
        >
          {feedbacks.map((feedback, index) => (
            <SwiperSlide key={feedback.id}>
              <div className="box mx-auto">
                <div className="detail-box">
                  <div className="client_info">
                    <h5 className="client_name">{feedback.user?.fullName || 'Anonymous'}</h5>
                    <div className="stars">{renderStars(feedback.rating)}</div>
                    <i className="fa fa-quote-left quote-icon" aria-hidden="true"></i>
                  </div>
                  <p className="client_message">{feedback.message}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="facebook-review-link text-center">
          <a
            href="https://www.instagram.com/stories/highlights/18002344562538976/"
            target="_blank"
            rel="noopener noreferrer"
            className="facebook-icon"
          >
            <i className="fa fa-instagram" aria-hidden="true"> {t('view_more_on_instagram')}</i>
          </a>
        </div>
      </div>
    </section>
  );
};

export default FeedbackSection;
